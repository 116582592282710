import {
  START, SUCCESS, ERROR, GET_COMMUNITY_MODELS, SORT_COMMUNITY_MODELS,
  SAVE_COMMUNITY_MODEL, SET_COMMUNITY_MODELS, DELETE_COMMUNITY_MODEL,
  UPDATE_COMMUNITY_MODEL
} from '../actions/types';
import { getError } from '../../utils/requestUtils';
import { addItemIfNotExists, removeRelatedNullObject } from '../../utils/arrayUtils';

const INITIAL_STATE = {
  communityModels: [],
  communityModel: null,

  getCommunityModels: {},
  sortCommunityModels: {},
  saveCommunityModel: {},
  deleteCommunityProductType: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case GET_COMMUNITY_MODELS + START:
      return { ...state, getCommunityModels: { loading: true } };
    case GET_COMMUNITY_MODELS + SUCCESS:
      return {
        ...state,
        getCommunityModels: { loading: false },
        communityModels: removeRelatedNullObject(data.items, 'model')
      };
    case GET_COMMUNITY_MODELS + ERROR:
      return { ...state, getCommunityModels: { loading: false, error } };

    case SORT_COMMUNITY_MODELS + START:
      return { ...state, sortCommunityModels: { loading: true } };
    case SORT_COMMUNITY_MODELS + SUCCESS:
      return { ...state, sortCommunityModels: { loading: false } };
    case SORT_COMMUNITY_MODELS + ERROR:
      return { ...state, sortCommunityModels: { loading: false, error } };

    case SAVE_COMMUNITY_MODEL + START:
      return { ...state, saveCommunityModel: { loading: true } };
    case SAVE_COMMUNITY_MODEL + SUCCESS:
      return {
        ...state,
        saveCommunityModel: { loading: false },
        communityModel: data.item,
        communityModels: addItemIfNotExists(state.communityModels, data.item)
      };
    case SAVE_COMMUNITY_MODEL + ERROR:
      return { ...state, saveCommunityModel: { loading: false, error } };

    case DELETE_COMMUNITY_MODEL + START:
      return { ...state, deleteCommunityProductType: { loading: true } };
    case DELETE_COMMUNITY_MODEL + SUCCESS:
      return {
        ...state,
        deleteCommunityProductType: { loading: false },
        communityModels: state.communityModels.filter((s) => s.id !== data.item.id)
      };
    case DELETE_COMMUNITY_MODEL + ERROR:
      return { ...state, deleteCommunityProductType: { loading: false, error } };

    case SET_COMMUNITY_MODELS:
      return { ...state, communityModels: action.payload.communityModels };

    case UPDATE_COMMUNITY_MODEL + START:
      return { ...state, updateCommunityModel: { loading: true } };

    case UPDATE_COMMUNITY_MODEL + SUCCESS:
      return {
        ...state,
        updateCommunityModel: { loading: false },
        communityModel: data.item,
        communityModels: addItemIfNotExists(state.communityModels, data.item)
      };

    case UPDATE_COMMUNITY_MODEL + ERROR:
      return { ...state, updateCommunityModel: { loading: false, error } };

    default:
      return { ...state };
  }
}
