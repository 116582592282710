import {
  OPEN_MODAL,
  CLOSE_MODAL
} from '../actions/types';

const INITIAL_STATE = {};

export default function reducer(state = INITIAL_STATE, action) {
  const { modalName, ...options } = action.payload || {};

  switch (action.type) {
    case OPEN_MODAL:
      return { ...state, [modalName]: options };
    case CLOSE_MODAL:
      return { ...state, [modalName]: { ...options, params: state[modalName].params } };

    default:
      return { ...state };
  }
}
