import {
  START,
  SUCCESS,
  ERROR,
  SAVE_FLOOR,
  DELETE_FLOOR,
  LIST_FLOORS,
  SORT_FLOORS,
  SET_FLOOR_OPTIONS,
  SET_FLOOR_GROUPS,
  SET_RELOAD_FLOORS
} from '../actions/types';
import { getError } from '../../utils/requestUtils';
import { setSortedFloorGroups } from '../../utils/floorGroupUtils';
import { setSortedFloorOptions } from '../../utils/floorOptionUtils';

const INITIAL_STATE = {
  floors: [],
  floor: null,
  reloadFloors: false,

  listFloors: {},
  saveFloor: {},
  deleteFloor: {},
  sortFloors: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case LIST_FLOORS + START:
      return { ...state, listFloors: { loading: true } };
    case LIST_FLOORS + SUCCESS:
      return { ...state, listFloors: { loading: false }, floors: data.items };
    case LIST_FLOORS + ERROR:
      return { ...state, listFloors: { loading: false, error } };

    case SAVE_FLOOR + START:
      return { ...state, saveFloor: { loading: true } };
    case SAVE_FLOOR + SUCCESS:
      return { ...state, saveFloor: { loading: false }, floor: data.item };
    case SAVE_FLOOR + ERROR:
      return { ...state, saveFloor: { loading: false, error } };

    case DELETE_FLOOR + START:
      return { ...state, deleteFloor: { loading: true } };
    case DELETE_FLOOR + SUCCESS:
      return {
        ...state,
        deleteFloor: { loading: false },
        floors: state.floors.filter((m) => m.id !== data.item.id)
      };
    case DELETE_FLOOR + ERROR:
      return { ...state, deleteFloor: { loading: false, error } };

    case SORT_FLOORS + START:
      return { ...state, sortFloors: { loading: true } };
    case SORT_FLOORS + SUCCESS:
      return { ...state, sortFloors: { loading: false }, floors: data.items };
    case SORT_FLOORS + ERROR:
      return { ...state, sortFloors: { loading: false, error } };

    case SET_FLOOR_GROUPS:
      return {
        ...state,
        floors: setSortedFloorGroups(state.floors,
          action.payload.floorId, action.payload.floorGroups)
      };

    case SET_FLOOR_OPTIONS:
      return {
        ...state,
        floors: setSortedFloorOptions(state.floors,
          action.payload.floorGroupId, action.payload.floorOptions)
      };

    case SET_RELOAD_FLOORS:
      return { ...state, reloadFloors: action.payload.reload };

    default:
      return { ...state };
  }
}
