import {
  START, SUCCESS, ERROR, LOGOUT, SET_LOGIN_EMAIL, SET_LOGIN_MEDIUM
} from '../actions/types';
import { getError } from '../../utils/requestUtils';

const INITIAL_STATE = {
  loginEmail: null,
  loginMedium: null,

  logout: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const error = getError(action.payload);

  switch (action.type) {
    case SET_LOGIN_EMAIL:
      return { ...state, loginEmail: action.payload.loginEmail };

    case SET_LOGIN_MEDIUM:
      return { ...state, loginMedium: action.payload.loginMedium };

    case LOGOUT + START:
      return { ...state, logout: { loading: true } };
    case LOGOUT + SUCCESS:
      return { ...state, logout: { loading: false } };
    case LOGOUT + ERROR:
      return { ...state, logout: { loading: false, error } };

    default:
      return { ...state };
  }
}
