import {
  START, SUCCESS, ERROR,
  LIST_WEBHOOK_DELIVERIES, CLEAN_WEBHOOK_DELIVERIES, GET_WEBHOOK_DELIVERY,
  DELETE_WEBHOOK_DELIVERY, SET_WEBHOOK_DELIVERY
} from '../actions/types';
import { getError } from '../../utils/requestUtils';

const INITIAL_STATE = {
  webhookDeliveries: [],
  webhookDelivery: null,

  listWebhookDeliveries: {},
  getWebhookDelivery: {},
  deleteWebhookDelivery: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case LIST_WEBHOOK_DELIVERIES + START:
      return { ...state, listWebhookDeliveries: { loading: true } };
    case LIST_WEBHOOK_DELIVERIES + SUCCESS:
      return {
        ...state,
        listWebhookDeliveries: { loading: false },
        webhookDeliveries: data.items
      };
    case LIST_WEBHOOK_DELIVERIES + ERROR:
      return { ...state, listWebhookDeliveries: { loading: false, error } };

    case CLEAN_WEBHOOK_DELIVERIES + SUCCESS:
      return {
        ...state,
        listWebhookDeliveries: { loading: false },
        webhookDeliveries: [],
        ...action.payload
      };

    case GET_WEBHOOK_DELIVERY + START:
      return { ...state, getWebhookDelivery: { loading: true } };
    case GET_WEBHOOK_DELIVERY + SUCCESS:
      return { ...state, getWebhookDelivery: { loading: false }, webhookDelivery: data.item };
    case GET_WEBHOOK_DELIVERY + ERROR:
      return { ...state, getWebhookDelivery: { loading: false, error } };

    case DELETE_WEBHOOK_DELIVERY + START:
      return { ...state, deleteWebhookDelivery: { loading: true } };
    case DELETE_WEBHOOK_DELIVERY + SUCCESS:
      return {
        ...state,
        deleteWebhookDelivery: { loading: false },
        webhookDeliveries: state.webhookDeliveries.filter((s) => s.id !== data.item.id)
      };
    case DELETE_WEBHOOK_DELIVERY + ERROR:
      return { ...state, deleteWebhookDelivery: { loading: false, error } };

    case SET_WEBHOOK_DELIVERY:
      return { ...state, webhookDelivery: action.payload.webhookDelivery };

    default:
      return { ...state };
  }
}
