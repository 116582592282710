import v from 'voca';

export function updateAttributesInItem(item, itemId, attributes) {
  if (item?.id !== itemId) return item;
  return { ...item, ...attributes };
}

export function addOrUpdateItems(existingItems, itemsToAdd) {
  const finalItems = [...existingItems];
  itemsToAdd.forEach((item) => {
    const index = finalItems.findIndex((ei) => ei.id === item.id);
    if (index === -1) finalItems.push(item);
    else finalItems[index] = item;
  });

  return finalItems;
}

export function removeItemFromItems(items, item) {
  return items.filter((i) => i.id !== item.id);
}

// Remove price rules only when priceableType is null
export function removeDependentPriceRules(priceRules, priceRule) {
  const isModelPriceRule = v.isBlank(priceRule.priceableType);
  if (!isModelPriceRule) return priceRules;

  let filteredPriceRules;
  const { community, communityPhase } = priceRule;
  if (communityPhase) {
    // Remove all price rules for community phase
    filteredPriceRules = priceRules.filter((pr) => pr.communityPhase?.id !== communityPhase.id);
  } else {
    // Remove all price rules for community but exclude the community phase price rules
    const communityPhasePriceRules = priceRules.filter((pr) => v.isBlank(pr.priceableType)
      && pr.communityPhase);
    const communityPhasePriceRuleIds = communityPhasePriceRules.map((pr) => pr.communityPhase.id);
    filteredPriceRules = priceRules.filter((pr) => pr.community.id !== community.id
        || communityPhasePriceRuleIds.includes(pr.communityPhase?.id));
  }
  return filteredPriceRules;
}
