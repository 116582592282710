import {
  START,
  SUCCESS,
  ERROR,
  LIST_HOMES,
  SET_RELOAD_HOMES,
  DELETE_HOME,
  GET_HOME,
  SET_HOME_ATTRIBUTES,
  CLEAN_HOMES
} from '../actions/types';
import { getError } from '../../utils/requestUtils';
import { updateAttributesInItem } from '../utils';

const INITIAL_STATE = {
  homes: [],
  reloadHomes: false,
  home: null,
  pagination: null,

  listHomes: {},
  getHome: {},
  deleteHome: {},
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case LIST_HOMES + START:
      return { ...state, listHomes: { loading: true } };
    case LIST_HOMES + SUCCESS:
      return {
        ...state,
        listHomes: { loading: false },
        homes: data.result.items,
        pagination: data.result.pagination
      };
    case LIST_HOMES + ERROR:
      return { ...state, listHomes: { loading: false, error } };

    case GET_HOME + START:
      return { ...state, getHome: { loading: true } };
    case GET_HOME + SUCCESS:
      return { ...state, getHome: { loading: false }, home: data.item };
    case GET_HOME + ERROR:
      return { ...state, getHome: { loading: false, error } };

    case SET_RELOAD_HOMES:
      return { ...state, reloadHomes: action.payload.reload };

    case DELETE_HOME + START:
      return { ...state, deleteHome: { loading: true } };
    case DELETE_HOME + SUCCESS:
      return {
        ...state,
        deleteHome: { loading: false },
        homes: state.homes.filter((h) => h.id !== data.item.id)
      };
    case DELETE_HOME + ERROR:
      return { ...state, deleteHome: { loading: false, error } };

    case SET_HOME_ATTRIBUTES:
      return {
        ...state,
        home: updateAttributesInItem(state.home, action.payload.homeId,
          action.payload.attributes),
      };

    case CLEAN_HOMES + SUCCESS:
      return {
        ...state,
        listHomes: { loading: false },
        homes: [],
        pagination: null,
        ...action.payload
      };

    default:
      return { ...state };
  }
}
