import {
  RELOAD_PROJECT_BUILDINGS,
  SET_PROJECT_BUILDINGS_VIEW_MODE
} from '../actions/types';
import { ViewModes } from '../../constants';

const INITIAL_STATE = {
  reload: false,
  viewMode: ViewModes.GRID
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RELOAD_PROJECT_BUILDINGS:
      return { ...state, reload: action.payload.reload };

    case SET_PROJECT_BUILDINGS_VIEW_MODE:
      return { ...state, viewMode: action.payload.viewMode };

    default:
      return { ...state };
  }
}
