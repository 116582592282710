import {
  CLEAN_QUICK_POSSESSIONS,
  DELETE_QUICK_POSSESSION,
  ERROR,
  GET_QUICK_POSSESSION,
  GET_QUICK_POSSESSIONS,
  SAVE_QUICK_POSSESSION,
  SET_QUICK_POSSESSION_VIEW_MODE,
  SET_RELOAD_QUICK_POSSESSIONS,
  START,
  SUCCESS,
  SET_QUICK_POSSESSION_COLUMNS,
  SET_QUICK_POSSESSION_FILTERS
} from '../actions/types';
import { getError } from '../../utils/requestUtils';
import { DefaultQuickPossessionColumns, ViewModes } from '../../constants';

const INITIAL_STATE = {
  quickPossessions: [],
  quickPossession: undefined,
  viewMode: ViewModes.LIST,
  pagination: null,
  reloadQuickPossessions: false,
  columns: DefaultQuickPossessionColumns,
  filters: {},

  getQuickPossessions: { loading: false },
  getQuickPossession: { loading: false },
  saveQuickPossession: { loading: false },
  deleteQuickPossession: { loading: false }
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case GET_QUICK_POSSESSIONS + START:
      return { ...state, getQuickPossessions: { loading: true } };
    case GET_QUICK_POSSESSIONS + SUCCESS:
      return {
        ...state,
        getQuickPossessions: { loading: false },
        quickPossessions: data.result.items,
        pagination: data.result.pagination
      };
    case GET_QUICK_POSSESSIONS + ERROR:
      return { ...state, getQuickPossessions: { loading: false, error } };

    case CLEAN_QUICK_POSSESSIONS + SUCCESS:
      return {
        ...state,
        getQuickPossessions: { loading: false },
        quickPossessions: [],
        pagination: null,
        ...action.payload
      };

    case GET_QUICK_POSSESSION + START:
      return { ...state, getQuickPossession: { loading: true } };
    case GET_QUICK_POSSESSION + SUCCESS:
      return { ...state, getQuickPossession: { loading: false }, quickPossession: data.item };
    case GET_QUICK_POSSESSION + ERROR:
      return { ...state, getQuickPossession: { loading: false, error } };

    case SAVE_QUICK_POSSESSION + START:
      return { ...state, saveQuickPossession: { loading: true } };
    case SAVE_QUICK_POSSESSION + SUCCESS:
      return { ...state, saveQuickPossession: { loading: false }, quickPossession: data.item };
    case SAVE_QUICK_POSSESSION + ERROR:
      return { ...state, saveQuickPossession: { loading: false, error } };

    case DELETE_QUICK_POSSESSION + START:
      return { ...state, deleteQuickPossession: { loading: true } };
    case DELETE_QUICK_POSSESSION + SUCCESS:
      return { ...state, deleteQuickPossession: { loading: false } };
    case DELETE_QUICK_POSSESSION + ERROR:
      return { ...state, deleteQuickPossession: { loading: false, error } };

    case SET_QUICK_POSSESSION_VIEW_MODE:
      return { ...state, viewMode: action.payload.viewMode };

    case SET_RELOAD_QUICK_POSSESSIONS:
      return { ...state, reloadQuickPossessions: action.payload.reload };

    case SET_QUICK_POSSESSION_COLUMNS:
      return { ...state, columns: action.payload.columns };

    case SET_QUICK_POSSESSION_FILTERS:
      return { ...state, filters: action.payload.filters };

    default:
      return { ...state };
  }
}
