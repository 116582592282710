import {
  START, SUCCESS, ERROR,
  GET_MODELS, SAVE_MODEL, CLEAN_MODELS, GET_MODEL, DELETE_MODEL,
  SET_MODEL_PALETTES, SET_MODEL_COMMUNITIES, SET_MODELS_SEARCH_FILTERS, SET_MODELS_VIEW_MODE
} from '../actions/types';
import { getError } from '../../utils/requestUtils';
import { ViewModes } from '../../constants';

const INITIAL_STATE = {
  models: [],
  model: null,
  pagination: null,
  searchFilters: {},
  viewMode: ViewModes.GRID,

  getModels: {},
  getModel: {},
  saveModel: {},
  deleteModel: {},
  setModelPalettes: {},
  setModelCommunities: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case GET_MODELS + START:
      return { ...state, getModels: { loading: true } };
    case GET_MODELS + SUCCESS:
      return {
        ...state,
        getModels: { loading: false },
        models: [...data.result.items],
        pagination: data.result.pagination
      };
    case GET_MODELS + ERROR:
      return { ...state, getModels: { loading: false, error } };

    case CLEAN_MODELS + SUCCESS:
      return {
        ...state,
        getModels: { loading: false },
        models: [],
        pagination: null,
        ...action.payload
      };

    case GET_MODEL + START:
      return { ...state, getModel: { loading: true }, model: null };
    case GET_MODEL + SUCCESS:
      return { ...state, getModel: { loading: false }, model: data.item };
    case GET_MODEL + ERROR:
      return { ...state, getModel: { loading: false, error } };

    case SAVE_MODEL + START:
      return { ...state, saveModel: { loading: true } };
    case SAVE_MODEL + SUCCESS:
      return { ...state, saveModel: { loading: false }, model: data.item };
    case SAVE_MODEL + ERROR:
      return { ...state, saveModel: { loading: false, error } };

    case DELETE_MODEL + START:
      return { ...state, deleteModel: { loading: true } };
    case DELETE_MODEL + SUCCESS:
      return {
        ...state,
        deleteModel: { loading: false },
        models: state.models.filter((m) => m.id !== data.item.id)
      };
    case DELETE_MODEL + ERROR:
      return { ...state, deleteModel: { loading: false, error } };

    case SET_MODEL_PALETTES + START:
      return { ...state, setModelPalettes: { loading: true } };
    case SET_MODEL_PALETTES + SUCCESS:
      return { ...state, setModelPalettes: { loading: false } };
    case SET_MODEL_PALETTES + ERROR:
      return { ...state, setModelPalettes: { loading: false, error } };

    case SET_MODEL_COMMUNITIES + START:
      return { ...state, setModelCommunities: { loading: true } };
    case SET_MODEL_COMMUNITIES + SUCCESS:
      return { ...state, setModelCommunities: { loading: false } };
    case SET_MODEL_COMMUNITIES + ERROR:
      return { ...state, setModelCommunities: { loading: false, error } };

    case SET_MODELS_SEARCH_FILTERS:
      return {
        ...state,
        searchFilters: { ...state.searchFilters, ...action.payload.filters }
      };

    case SET_MODELS_VIEW_MODE:
      return { ...state, viewMode: action.payload.viewMode };

    default:
      return { ...state };
  }
}
