import {
  START, SUCCESS, ERROR,
  SAVE_ELEVATION, DELETE_ELEVATION, GET_ELEVATIONS, SORT_ELEVATIONS, SET_ELEVATIONS
} from '../actions/types';
import { getError } from '../../utils/requestUtils';

const INITIAL_STATE = {
  elevations: [],
  elevation: null,

  getElevations: {},
  saveElevation: {},
  deleteElevation: {},
  sortElevations: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case GET_ELEVATIONS + START:
      return { ...state, getElevations: { loading: true } };
    case GET_ELEVATIONS + SUCCESS:
      return { ...state, getElevations: { loading: false }, elevations: data.items };
    case GET_ELEVATIONS + ERROR:
      return { ...state, getElevations: { loading: false, error } };

    case SAVE_ELEVATION + START:
      return { ...state, saveElevation: { loading: true } };
    case SAVE_ELEVATION + SUCCESS:
      return { ...state, saveElevation: { loading: false }, elevation: data.item };
    case SAVE_ELEVATION + ERROR:
      return { ...state, saveElevation: { loading: false, error } };

    case DELETE_ELEVATION + START:
      return { ...state, deleteElevation: { loading: true } };
    case DELETE_ELEVATION + SUCCESS:
      return {
        ...state,
        deleteElevation: { loading: false },
        elevations: state.elevations.filter((m) => m.id !== data.item.id)
      };
    case DELETE_ELEVATION + ERROR:
      return { ...state, deleteElevation: { loading: false, error } };

    case SET_ELEVATIONS:
      return { ...state, elevations: action.payload.elevations };

    case SORT_ELEVATIONS + START:
      return { ...state, sortElevations: { loading: true } };
    case SORT_ELEVATIONS + SUCCESS:
      return { ...state, sortElevations: { loading: false } };
    case SORT_ELEVATIONS + ERROR:
      return { ...state, sortElevations: { loading: false, error } };

    default:
      return { ...state };
  }
}
