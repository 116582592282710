import {
  ADD_PRICE_RULES, REMOVE_PRICE_RULE, CLEAN_PRICE_RULES, SUCCESS
} from '../actions/types';
import { addOrUpdateItems, removeDependentPriceRules, removeItemFromItems } from '../utils';

const INITIAL_STATE = {
  priceRules: []
};

export default function reducer(state = INITIAL_STATE, action) {
  let filteredPriceRules = [];

  switch (action.type) {
    case CLEAN_PRICE_RULES + SUCCESS:
      return { ...state, priceRules: [] };

    case ADD_PRICE_RULES:
      return {
        ...state,
        priceRules: addOrUpdateItems(state.priceRules, action.payload.priceRules)
      };

    case REMOVE_PRICE_RULE:
      filteredPriceRules = removeItemFromItems(state.priceRules, action.payload.priceRule);
      filteredPriceRules = removeDependentPriceRules(filteredPriceRules, action.payload.priceRule);
      return { ...state, priceRules: filteredPriceRules };

    default:
      return { ...state };
  }
}
