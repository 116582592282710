/**
 * Model validation errors: {[attribute]: string[], ...}
 * Error messages: string
 * @param payload
 * @return {string|{}|null}
 */
export function getError(payload) {
  if (payload?.graphQLErrors) {
    const graphQLErrors = payload.graphQLErrors || [];
    if (graphQLErrors.length === 0) return null;

    const response = graphQLErrors[0].extensions?.exception.response;
    if (response) {
      const messages = typeof response === 'string' ? response : response?.message;
      if (typeof messages === 'string') return messages;
      if (messages === undefined) return null;

      let validationErrors = {};
      messages.forEach((message) => {
        validationErrors = { ...validationErrors, ...message };
      });
      return validationErrors;
    }
  }
  return null;
}

/**
 * Database or another internal errors: {[attribute]: string[], ...}
 * Error messages: string
 * @param payload
 * @return {string|null}
 */
export function getInternalServerError(payload) {
  if (payload?.graphQLErrors) {
    const graphQLErrors = payload.graphQLErrors || [];
    if (graphQLErrors.length === 0) return null;

    const response = graphQLErrors[0];
    const messages = typeof response === 'string' ? response : response?.message;
    if (typeof messages === 'string') return messages;

    let validationErrors = {};
    messages.forEach((message) => {
      validationErrors = { ...validationErrors, ...message };
    });
    return validationErrors;
  }
  return null;
}

export function getGraphQLErrors(payload) {
  return getError(payload) || getInternalServerError(payload);
}
