import React, { createContext } from 'react';

import socket from './socket';

export const SocketContext = createContext({});

const SocketProvider = ({ children }) => (
  <SocketContext.Provider value={socket}>
    {children}
  </SocketContext.Provider>
);

export default SocketProvider;
