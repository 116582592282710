import { getError } from '../../utils/requestUtils';
import {
  START, SUCCESS, ERROR, LIST_PARTY_CONTACTS, CLEAN_PARTY_CONTACTS
} from '../actions/types';

const INITIAL_STATE = {
  partyContacts: [],

  listPartyContacts: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case LIST_PARTY_CONTACTS + START:
      return { ...state, listPartyContacts: { loading: true } };
    case LIST_PARTY_CONTACTS + SUCCESS:
      return {
        ...state,
        listPartyContacts: { loading: false },
        partyContacts: data.items
      };
    case LIST_PARTY_CONTACTS + ERROR:
      return { ...state, listPartyContacts: { loading: false, error } };

    case CLEAN_PARTY_CONTACTS + SUCCESS:
      return { ...state, partyContacts: [] };

    default:
      return { ...state };
  }
}
