import {
  START, SUCCESS, ERROR,
  SAVE_FORM, DELETE_FORM, GET_FORMS, CLEAN_FORMS, GET_FORM
} from '../actions/types';
import { getError } from '../../utils/requestUtils';
import { addItemIfNotExists } from '../../utils/arrayUtils';

const INITIAL_STATE = {
  forms: [],
  form: null,

  getForms: {},
  getForm: {},
  saveForm: {},
  deleteForm: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case GET_FORMS + START:
      return { ...state, getForms: { loading: true } };
    case GET_FORMS + SUCCESS:
      return {
        ...state,
        getForms: { loading: false },
        forms: data.items
      };
    case GET_FORMS + ERROR:
      return { ...state, getForms: { loading: false, error } };

    case CLEAN_FORMS + SUCCESS:
      return {
        ...state,
        getForms: { loading: false },
        forms: [],
        ...action.payload
      };

    case GET_FORM + START:
      return { ...state, getForm: { loading: true } };
    case GET_FORM + SUCCESS:
      return { ...state, getForm: { loading: false }, form: data.item };
    case GET_FORM + ERROR:
      return { ...state, getForm: { loading: false, error } };

    case SAVE_FORM + START:
      return { ...state, saveForm: { loading: true } };
    case SAVE_FORM + SUCCESS:
      return {
        ...state,
        saveForm: { loading: false },
        form: data.item,
        forms: addItemIfNotExists(state.forms, data.item)
      };
    case SAVE_FORM + ERROR:
      return { ...state, saveForm: { loading: false, error } };

    case DELETE_FORM + START:
      return { ...state, deleteForm: { loading: true } };
    case DELETE_FORM + SUCCESS:
      return {
        ...state,
        deleteForm: { loading: false },
        forms: state.forms.filter((s) => s.id !== data.item.id)
      };
    case DELETE_FORM + ERROR:
      return { ...state, deleteForm: { loading: false, error } };

    default:
      return { ...state };
  }
}
