import {
  START,
  SUCCESS,
  ERROR,
  GET_OFFERS,
  GET_OFFER,
  DELETE_OFFER,
  SAVE_OFFER,
  SET_OFFER_FILTERS
} from '../actions/types';
import { getError } from '../../utils/requestUtils';
import { addItemIfNotExists } from '../../utils/arrayUtils';

const INITIAL_STATE = {
  offers: [],
  offer: null,
  pagination: null,
  filters: {},

  getOffers: {},
  getOffer: {},
  saveOffer: {},
  deleteOffer: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case GET_OFFERS + START:
      return { ...state, getOffers: { loading: true } };
    case GET_OFFERS + SUCCESS:
      return {
        ...state,
        getOffers: { loading: false },
        offers: data.result.items,
        pagination: data.result.pagination
      };
    case GET_OFFERS + ERROR:
      return { ...state, getOffers: { loading: false, error } };

    case SAVE_OFFER + START:
      return { ...state, saveOffer: { loading: true } };
    case SAVE_OFFER + SUCCESS:
      return {
        ...state,
        saveOffer: { loading: false },
        offer: data.item,
        offers: addItemIfNotExists(state.offers, data.item)
      };
    case SAVE_OFFER + ERROR:
      return { ...state, saveOffer: { loading: false, error } };

    case GET_OFFER + START:
      return { ...state, getOffer: { loading: true } };
    case GET_OFFER + SUCCESS:
      return { ...state, getOffer: { loading: false }, offer: data.item };
    case GET_OFFER + ERROR:
      return { ...state, getOffer: { loading: false, error } };

    case DELETE_OFFER + START:
      return { ...state, deleteOffer: { loading: true } };
    case DELETE_OFFER + SUCCESS:
      return {
        ...state,
        deleteOffer: { loading: false },
        offers: state.offers.filter((o) => o.id !== data.item.id)
      };
    case DELETE_OFFER + ERROR:
      return { ...state, deleteOffer: { loading: false, error } };

    case SET_OFFER_FILTERS:
      return { ...state, filters: action.payload.filters };

    default:
      return { ...state };
  }
}
