import { combineReducers } from 'redux';

import communities from './communityReducers';
import models from './modelReducers';
import modelImages from './modelImageReducers';
import elevations from './elevationReducers';
import modals from './modalReducers';
import floors from './floorReducers';
import floorGroups from './floorGroupReducers';
import floorOptions from './floorOptionReducers';
import specifications from './specificationReducers';
import packages from './packageReducers';
import users from './userReducers';
import productTypes from './productTypeReducers';
import companies from './companyReducers';
import auth from './authReducers';
import palettes from './paletteReducers';
import communityProductTypes from './communityProductTypeReducers';
import documents from './documentReducers';
import importExports from './importExportReducers';
import communityModels from './communityModelsReducers';
import customers from './customerReducers';
import projects from './projectReducers';
import buildingModels from './buildingModelReducers';
import units from './unitReducers';
import options from './optionReducers';
import optionTypes from './optionTypeReducers';
import buildingFloors from './buildingFloorReducers';
import offers from './offerReducers';
import overview from './overviewReducers';
import externalDataSources from './externalDataSourceReducers';
import tours from './tourReducers';
import modelPackages from './modelPackageReducers';
import modelPalettes from './modelPaletteReducers';
import activities from './activityReducers';
import deposits from './depositReducers';
import quickPossessions from './quickPossessionReducers';
import taxRates from './taxRateReducers';
import cities from './cityReducers';
import projectPalettes from './projectPaletteReducers';
import projectPackages from './projectPackageReducers';
import projectBuildings from './projectBuildingReducers';
import messages from './messageReducers';
import webhooks from './webhookReducers';
import webhookDeliveries from './webhookDeliveryReducers';
import buildingModelPackages from './buildingModelPackageReducers';
import buildingModelPalettes from './buildingModelPaletteReducers';
import homes from './homeReducers';
import preApprovals from './preApprovalReducers';
import sellerSpaces from './sellerSpaceReducers';
import priceRules from './priceRuleReducers';
import navigation from './navigationReducers';
import sendGrid from './sendGridReducers';
import forms from './formReducers';
import trackingPages from './trackingPageReducers';
import propertyTaxRates from './propertyTaxRateReducers';
import menu from './menuReducers';
import companyWorkflows from './companyWorkflowReducers';
import teams from './teamReducers';
import teamUsers from './teamUserReducers';
import partyContacts from './partyContactsReducers';
import salesInvitation from './salesInvitationReducers';
import deals from './dealReducers';
import mortgageProviders from './mortgageProviderReducers';

export default function getRootReducers() {
  return combineReducers({
    auth,
    communities,
    models,
    modelImages,
    elevations,
    modals,
    floors,
    floorGroups,
    floorOptions,
    specifications,
    users,
    productTypes,
    companies,
    packages,
    palettes,
    communityProductTypes,
    documents,
    importExports,
    communityModels,
    customers,
    projects,
    units,
    options,
    optionTypes,
    buildingFloors,
    offers,
    quickPossessions,
    overview,
    externalDataSources,
    tours,
    activities,
    modelPackages,
    modelPalettes,
    deposits,
    taxRates,
    cities,
    projectPalettes,
    projectPackages,
    projectBuildings,
    messages,
    webhooks,
    webhookDeliveries,
    buildingModels,
    buildingModelPackages,
    buildingModelPalettes,
    homes,
    sellerSpaces,
    preApprovals,
    priceRules,
    navigation,
    sendGrid,
    forms,
    trackingPages,
    propertyTaxRates,
    menu,
    companyWorkflows,
    teams,
    teamUsers,
    partyContacts,
    salesInvitation,
    deals,
    mortgageProviders
  });
}
