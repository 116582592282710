import {
  START, SUCCESS, ERROR,
  SAVE_WEBHOOK, DELETE_WEBHOOK, LIST_WEBHOOKS, CLEAN_WEBHOOKS, GET_WEBHOOK
} from '../actions/types';
import { getError } from '../../utils/requestUtils';
import { addItemIfNotExists } from '../../utils/arrayUtils';

const INITIAL_STATE = {
  webhooks: [],
  webhook: null,

  listWebhooks: {},
  getWebhook: {},
  saveWebhook: {},
  deleteWebhook: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case LIST_WEBHOOKS + START:
      return { ...state, listWebhooks: { loading: true } };
    case LIST_WEBHOOKS + SUCCESS:
      return {
        ...state,
        listWebhooks: { loading: false },
        webhooks: data.items
      };
    case LIST_WEBHOOKS + ERROR:
      return { ...state, listWebhooks: { loading: false, error } };

    case CLEAN_WEBHOOKS + SUCCESS:
      return {
        ...state,
        listWebhooks: { loading: false },
        webhooks: [],
        ...action.payload
      };

    case GET_WEBHOOK + START:
      return { ...state, getWebhook: { loading: true } };
    case GET_WEBHOOK + SUCCESS:
      return { ...state, getWebhook: { loading: false }, webhook: data.item };
    case GET_WEBHOOK + ERROR:
      return { ...state, getWebhook: { loading: false, error } };

    case SAVE_WEBHOOK + START:
      return { ...state, saveWebhook: { loading: true } };
    case SAVE_WEBHOOK + SUCCESS:
      return {
        ...state,
        saveWebhook: { loading: false },
        webhook: data.item,
        webhooks: addItemIfNotExists(state.webhooks, data.item)
      };
    case SAVE_WEBHOOK + ERROR:
      return { ...state, saveWebhook: { loading: false, error } };

    case DELETE_WEBHOOK + START:
      return { ...state, deleteWebhook: { loading: true } };
    case DELETE_WEBHOOK + SUCCESS:
      return {
        ...state,
        deleteWebhook: { loading: false },
        webhooks: state.webhooks.filter((s) => s.id !== data.item.id)
      };
    case DELETE_WEBHOOK + ERROR:
      return { ...state, deleteWebhook: { loading: false, error } };

    default:
      return { ...state };
  }
}
