import {
  START,
  SUCCESS,
  ERROR,
  SENDGRID_LIST_CONTACT_LISTS,
  SENDGRID_CLEAN_CONTACT_LISTS,
  SENDGRID_DELETE_CONTACT_LIST,
  SENDGRID_GET_CONTACT_LIST,
  SENDGRID_SAVE_CONTACT_LIST,
  SENDGRID_SET_CONTACT_LIST,
  SENDGRID_SET_CONTACTS,
  SENDGRID_CLEAN_CONTACTS,
  SENDGRID_SAVE_CONTACT,
  SENDGRID_DELETE_CONTACT_FROM_LIST
} from '../actions/types';
import { getError } from '../../utils/requestUtils';
import { addItemIfNotExists } from '../../utils/arrayUtils';

const INITIAL_STATE = {
  contactLists: [],
  contactList: null,

  contacts: [],
  contact: null,

  listContactLists: {},
  getContactList: {},
  saveContactList: {},
  deleteContactList: {},

  saveContact: {},
  deleteContactFromList: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case SENDGRID_LIST_CONTACT_LISTS + START:
      return { ...state, listContactLists: { loading: true } };
    case SENDGRID_LIST_CONTACT_LISTS + SUCCESS:
      return {
        ...state,
        listContactLists: { loading: false },
        contactLists: data.items
      };
    case SENDGRID_LIST_CONTACT_LISTS + ERROR:
      return { ...state, listContactLists: { loading: false, error } };

    case SENDGRID_CLEAN_CONTACT_LISTS + SUCCESS:
      return {
        ...state,
        listContactLists: { loading: false },
        contactLists: [],
        ...action.payload
      };

    case SENDGRID_GET_CONTACT_LIST + START:
      return { ...state, getContactList: { loading: true } };
    case SENDGRID_GET_CONTACT_LIST + SUCCESS:
      return { ...state, getContactList: { loading: false }, contactList: data.item };
    case SENDGRID_GET_CONTACT_LIST + ERROR:
      return { ...state, getContactList: { loading: false, error } };

    case SENDGRID_SAVE_CONTACT_LIST + START:
      return { ...state, saveContactList: { loading: true } };
    case SENDGRID_SAVE_CONTACT_LIST + SUCCESS:
      return {
        ...state,
        saveContactList: { loading: false },
        contactList: data.item,
        contactLists: addItemIfNotExists(state.contactLists, data.item)
      };
    case SENDGRID_SAVE_CONTACT_LIST + ERROR:
      return { ...state, saveContactList: { loading: false, error } };

    case SENDGRID_DELETE_CONTACT_LIST + START:
      return { ...state, deleteContactList: { loading: true } };
    case SENDGRID_DELETE_CONTACT_LIST + SUCCESS:
      return {
        ...state,
        deleteContactList: { loading: false },
        contactLists: state.contactLists.filter((s) => s.id !== data.item.id)
      };
    case SENDGRID_DELETE_CONTACT_LIST + ERROR:
      return { ...state, deleteContactList: { loading: false, error } };

    case SENDGRID_SET_CONTACT_LIST:
      return { ...state, contactList: action.payload.contactList };

    case SENDGRID_SET_CONTACTS:
      return { ...state, contacts: action.payload.contacts };

    case SENDGRID_CLEAN_CONTACTS + SUCCESS:
      return {
        ...state,
        contacts: [],
        ...action.payload
      };

    case SENDGRID_SAVE_CONTACT + START:
      return { ...state, saveContact: { loading: true } };
    case SENDGRID_SAVE_CONTACT + SUCCESS:
      return {
        ...state,
        saveContact: { loading: false },
        contact: data.item,
        contacts: addItemIfNotExists(state.contacts, data.item, 'email')
      };
    case SENDGRID_SAVE_CONTACT + ERROR:
      return { ...state, saveContact: { loading: false, error } };

    case SENDGRID_DELETE_CONTACT_FROM_LIST + START:
      return { ...state, deleteContactFromList: { loading: true } };
    case SENDGRID_DELETE_CONTACT_FROM_LIST + SUCCESS:
      return {
        ...state,
        deleteContactFromList: { loading: false },
        contacts: state.contacts.filter((s) => s.id !== data.item.id)
      };
    case SENDGRID_DELETE_CONTACT_FROM_LIST + ERROR:
      return { ...state, deleteContactFromList: { loading: false, error } };

    default:
      return { ...state };
  }
}
