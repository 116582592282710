import {
  START, SUCCESS, ERROR,
  GET_COMMUNITIES, SAVE_COMMUNITY, CLEAN_COMMUNITIES, GET_COMMUNITY, DELETE_COMMUNITY,
  SET_COMMUNITY_SEARCH_FILTERS, SET_COMMUNITIES_VIEW_MODE
} from '../actions/types';
import { getError } from '../../utils/requestUtils';
import { ViewModes } from '../../constants';

const INITIAL_STATE = {
  communities: [],
  community: null,
  searchFilters: {
    query: null,
    sort: null
  },
  pagination: null,
  viewMode: ViewModes.GRID,

  getCommunities: {},
  getCommunity: {},
  saveCommunity: {},
  deleteCommunity: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case GET_COMMUNITIES + START:
      return { ...state, getCommunities: { loading: true } };
    case GET_COMMUNITIES + SUCCESS:
      return {
        ...state,
        getCommunities: { loading: false },
        communities: data.result.items,
        pagination: data.result.pagination
      };
    case GET_COMMUNITIES + ERROR:
      return { ...state, getCommunities: { loading: false, error } };

    case CLEAN_COMMUNITIES + SUCCESS:
      return {
        ...state,
        getCommunities: { loading: false },
        communities: [],
        pagination: null,
        ...action.payload
      };

    case SET_COMMUNITY_SEARCH_FILTERS + SUCCESS:
      return {
        ...state,
        searchFilters: {
          ...state.searchFilters,
          ...action.payload.searchFilters
        }
      };

    case GET_COMMUNITY + START:
      return { ...state, getCommunity: { loading: true } };
    case GET_COMMUNITY + SUCCESS:
      return { ...state, getCommunity: { loading: false }, community: data.item };
    case GET_COMMUNITY + ERROR:
      return { ...state, getCommunity: { loading: false, error } };

    case SAVE_COMMUNITY + START:
      return { ...state, saveCommunity: { loading: true } };
    case SAVE_COMMUNITY + SUCCESS:
      return { ...state, saveCommunity: { loading: false }, community: data.item };
    case SAVE_COMMUNITY + ERROR:
      return { ...state, saveCommunity: { loading: false, error } };

    case DELETE_COMMUNITY + START:
      return { ...state, deleteCommunity: { loading: true } };
    case DELETE_COMMUNITY + SUCCESS:
      return {
        ...state,
        deleteCommunity: { loading: false },
        community: data.item,
        communities: state.communities.filter((c) => c.id !== data.item.id)
      };
    case DELETE_COMMUNITY + ERROR:
      return { ...state, deleteCommunity: { loading: false, error } };

    case SET_COMMUNITIES_VIEW_MODE:
      return { ...state, viewMode: action.payload.viewMode };

    default:
      return { ...state };
  }
}
