import {
  ADD_ITEM_TO_NAVIGATION, RESET_NAVIGATION
} from '../actions/types';
import { addOrReplaceChild } from '../../utils/navigationUtils';

const INITIAL_STATE = {
  parent: null,
  children: []
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_ITEM_TO_NAVIGATION:
      return { ...state, children: addOrReplaceChild(state.children, action.payload.children) };
    case RESET_NAVIGATION:
      return { ...state, parent: null, children: [] };

    default:
      return { ...state };
  }
}
