import {
  START, SUCCESS, ERROR,
  GET_COMPANIES, SAVE_COMPANY, CLEAN_COMPANIES, GET_COMPANY, ARCHIVE_COMPANY,
  SET_CURRENT_COMPANY, RESTORE_COMPANY
} from '../actions/types';
import { getError } from '../../utils/requestUtils';

const INITIAL_STATE = {
  companies: [],
  company: null,
  currentCompany: null,
  pagination: null,

  getCompanies: {},
  getCompany: {},
  getCurrentCompany: {},
  saveCompany: {},
  deleteCompany: {},
  restoreCompany: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case GET_COMPANIES + START:
      return { ...state, getCompanies: { loading: true } };
    case GET_COMPANIES + SUCCESS:
      return {
        ...state,
        getCompanies: { loading: false },
        companies: data.result.items,
        pagination: data.result.pagination
      };
    case GET_COMPANIES + ERROR:
      return { ...state, getCompanies: { loading: false, error } };

    case CLEAN_COMPANIES + SUCCESS:
      return {
        ...state, getCompanies: { loading: false }, companies: [], pagination: null
      };

    case GET_COMPANY + START:
      return { ...state, getCompany: { loading: true }, company: null };
    case GET_COMPANY + SUCCESS:
      return { ...state, getCompany: { loading: false }, company: data.item };
    case GET_COMPANY + ERROR:
      return { ...state, getCompany: { loading: false, error } };

    case SET_CURRENT_COMPANY + START:
      return { ...state, getCurrentCompany: { loading: true } };
    case SET_CURRENT_COMPANY + SUCCESS:
      return { ...state, getCurrentCompany: { loading: false }, currentCompany: data.item };
    case SET_CURRENT_COMPANY + ERROR:
      return { ...state, getCurrentCompany: { loading: false, error } };

    case SAVE_COMPANY + START:
      return { ...state, saveCompany: { loading: true } };
    case SAVE_COMPANY + SUCCESS:
      return {
        ...state,
        saveCompany: { loading: false },
        company: data.item,
        currentCompany: state.currentCompany.id === data.item.id ? data.item : state.currentCompany
      };
    case SAVE_COMPANY + ERROR:
      return { ...state, saveCompany: { loading: false, error } };

    case ARCHIVE_COMPANY + START:
      return { ...state, deleteCompany: { loading: true } };
    case ARCHIVE_COMPANY + SUCCESS:
      return {
        ...state,
        deleteCompany: { loading: false },
        companies: state.companies.filter((m) => m.id !== data.item.id)
      };
    case ARCHIVE_COMPANY + ERROR:
      return { ...state, deleteCompany: { loading: false, error } };

    case RESTORE_COMPANY + START:
      return { ...state, restoreCompany: { loading: true } };
    case RESTORE_COMPANY + SUCCESS:
      return {
        ...state,
        restoreCompany: { loading: false },
      };
    case RESTORE_COMPANY + ERROR:
      return { ...state, restoreCompany: { loading: false, error } };

    default:
      return { ...state };
  }
}
