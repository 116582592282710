import {
  START,
  SUCCESS,
  ERROR,
  SAVE_CUSTOMER,
  GET_CUSTOMER,
  GET_CUSTOMERS,
  CLEAN_CUSTOMERS,
  SYNC_CUSTOMER_PROPERTY_INFO,
  RESET_CUSTOMER_VERIFICATIONS,
  SET_CUSTOMER_ATTRIBUTES,
  SET_CUSTOMERS_SORT_FIELD,
  SET_CUSTOMERS_SORT_ORDER,
  SET_CUSTOMER_COLUMNS,
  GET_CUSTOMER_FINANCIAL_REPORT,
  SET_CUSTOMER_FILTERS,
  SET_CUSTOMER_INTEREST_FILTERS,
  SET_ACTIVE_CUSTOMER_TAB, SHOW_CUSTOMER_FORM,
} from '../actions/types';
import { getError } from '../../utils/requestUtils';
import { updateAttributesInItem } from '../utils';
import { CustomerSortFields, CustomerTabs, SortOrders } from '../../constants';

const INITIAL_STATE = {
  customers: [],
  customer: null,
  pagination: null,
  sortField: CustomerSortFields.LAST_ACTIVE,
  sortOrder: SortOrders.DESC,
  columns: [],
  financialReport: null,
  showForm: false,
  filters: {},
  interestFilters: {},
  activeTab: CustomerTabs.ACTIVITY,

  getCustomers: {},
  saveCustomer: {},
  getCustomer: {},
  syncCustomerPropertyInfo: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case SAVE_CUSTOMER + START:
      return { ...state, saveCustomer: { loading: true } };
    case SAVE_CUSTOMER + SUCCESS:
      return {
        ...state,
        saveCustomer: { loading: false },
        customer: data.item
      };
    case SAVE_CUSTOMER + ERROR:
      return { ...state, saveCustomer: { loading: false, error } };

    case GET_CUSTOMER + START:
      return { ...state, getCustomer: { loading: true } };
    case GET_CUSTOMER + SUCCESS:
      return { ...state, getCustomer: { loading: false }, customer: data.item };
    case GET_CUSTOMER + ERROR:
      return { ...state, getCustomer: { loading: false, error } };

    case GET_CUSTOMERS + START:
      return { ...state, getCustomers: { loading: true } };
    case GET_CUSTOMERS + SUCCESS:
      return {
        ...state,
        getCustomers: { loading: false },
        customers: data.result.items,
        pagination: data.result.pagination,
      };
    case GET_CUSTOMERS + ERROR:
      return { ...state, getCustomers: { loading: false, error } };

    case CLEAN_CUSTOMERS + SUCCESS:
      return {
        ...state,
        getCustomers: { loading: false },
        customers: [],
        pagination: null,
      };

    case SYNC_CUSTOMER_PROPERTY_INFO + START:
      return { ...state, syncCustomerPropertyInfo: { loading: true } };
    case SYNC_CUSTOMER_PROPERTY_INFO + SUCCESS:
      return {
        ...state,
        syncCustomerPropertyInfo: { loading: false },
        customer: data.item
      };
    case SYNC_CUSTOMER_PROPERTY_INFO + ERROR:
      return { ...state, syncCustomerPropertyInfo: { loading: false, error } };

    case RESET_CUSTOMER_VERIFICATIONS + START:
      return { ...state, resetCustomerVerifications: { loading: true } };

    case RESET_CUSTOMER_VERIFICATIONS + SUCCESS:
      return {
        ...state,
        resetCustomerVerifications: { loading: false },
        customer: data.item
      };

    case RESET_CUSTOMER_VERIFICATIONS + ERROR:
      return { ...state, resetCustomerVerifications: { loading: false, error } };

    case SET_CUSTOMER_ATTRIBUTES:
      return {
        ...state,
        customer: updateAttributesInItem(
          state.customer,
          action.payload.id,
          action.payload.attributes
        ),
      };

    case SET_CUSTOMERS_SORT_FIELD:
      return { ...state, sortField: action.payload.sortField };

    case SET_CUSTOMERS_SORT_ORDER:
      return { ...state, sortOrder: action.payload.sortOrder };

    case SET_CUSTOMER_COLUMNS:
      return { ...state, columns: action.payload.columns };

    case GET_CUSTOMER_FINANCIAL_REPORT + START:
      return { ...state };
    case GET_CUSTOMER_FINANCIAL_REPORT + SUCCESS:
      return { ...state, financialReport: data.report };
    case GET_CUSTOMER_FINANCIAL_REPORT + ERROR:
      return { ...state };

    case SET_CUSTOMER_FILTERS:
      return { ...state, filters: action.payload.filters };

    case SET_CUSTOMER_INTEREST_FILTERS:
      return { ...state, interestFilters: action.payload.interestFilters };

    case SHOW_CUSTOMER_FORM:
      return { ...state, showForm: action.payload.showForm };

    case SET_ACTIVE_CUSTOMER_TAB:
      return { ...state, activeTab: action.payload.activeTab };

    default:
      return { ...state };
  }
}
