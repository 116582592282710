import io from 'socket.io-client';

const socket = io(process.env.GATSBY_SERVER_URL, {
  autoConnect: false
});

socket.on('connect', () => {
  console.log(`Client connected: ${socket.id}`);
});

socket.on('disconnect', () => {
  console.log(`Client disconnected: ${socket.id}`);
});

export default socket;
