import {
  START, SUCCESS, ERROR,
  SAVE_MODEL_PACKAGE, DELETE_MODEL_PACKAGE, LIST_MODEL_PACKAGES, RELOAD_MODEL_PACKAGES
} from '../actions/types';
import { getError } from '../../utils/requestUtils';
import { addItemIfNotExists } from '../../utils/arrayUtils';

const INITIAL_STATE = {
  modelPackages: [],
  modelPackage: null,
  reload: false,

  getModelPackages: {},
  saveModelPackage: {},
  deleteModelPackage: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case LIST_MODEL_PACKAGES + START:
      return { ...state, getModelPackages: { loading: true } };
    case LIST_MODEL_PACKAGES + SUCCESS:
      return {
        ...state,
        getModelPackages: { loading: false },
        modelPackages: data.items
      };
    case LIST_MODEL_PACKAGES + ERROR:
      return { ...state, getModelPackages: { loading: false, error } };

    case SAVE_MODEL_PACKAGE + START:
      return { ...state, saveModelPackage: { loading: true } };
    case SAVE_MODEL_PACKAGE + SUCCESS:
      return {
        ...state,
        saveModelPackage: { loading: false },
        modelPackage: data.item,
        modelPackages: addItemIfNotExists(state.modelPackages, data.item)
      };
    case SAVE_MODEL_PACKAGE + ERROR:
      return { ...state, saveModelPackage: { loading: false, error } };

    case DELETE_MODEL_PACKAGE + START:
      return { ...state, deleteModelPackage: { loading: true } };
    case DELETE_MODEL_PACKAGE + SUCCESS:
      return {
        ...state,
        deleteModelPackage: { loading: false },
        modelPackages: state.modelPackages.filter((s) => s.id !== data.item.id)
      };
    case DELETE_MODEL_PACKAGE + ERROR:
      return { ...state, deleteModelPackage: { loading: false, error } };

    case RELOAD_MODEL_PACKAGES:
      return { ...state, reload: action.payload.reload };

    default:
      return { ...state };
  }
}
