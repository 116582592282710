export const START = '_PENDING';
export const SUCCESS = '_FULFILLED';
export const ERROR = '_REJECTED';

export const SET_LOGIN_EMAIL = 'SET_LOGIN_EMAIL';
export const SET_LOGIN_MEDIUM = 'SET_LOGIN_MEDIUM';
export const LOGOUT = 'LOGOUT';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const GET_COMMUNITIES = 'GET_COMMUNITIES';
export const GET_COMMUNITY = 'GET_COMMUNITY';
export const CLEAN_COMMUNITIES = 'CLEAN_COMMUNITIES';
export const SAVE_COMMUNITY = 'SAVE_COMMUNITY';
export const DELETE_COMMUNITY = 'DELETE_COMMUNITY';
export const SET_COMMUNITY_SEARCH_FILTERS = 'SET_COMMUNITY_SEARCH_FILTERS';
export const SET_COMMUNITIES_VIEW_MODE = 'SET_COMMUNITIES_VIEW_MODE';

export const GET_MODELS = 'GET_MODELS';
export const GET_MODEL = 'GET_MODEL';
export const CLEAN_MODELS = 'CLEAN_MODELS';
export const SAVE_MODEL = 'SAVE_MODEL';
export const DELETE_MODEL = 'DELETE_MODEL';
export const SET_MODEL_PALETTES = 'SET_MODEL_PALETTES';

export const GET_ELEVATIONS = 'GET_ELEVATIONS';
export const SAVE_ELEVATION = 'SAVE_ELEVATION';
export const DELETE_ELEVATION = 'DELETE_ELEVATION';
export const SET_ELEVATIONS = 'SET_ELEVATIONS';
export const SORT_ELEVATIONS = 'SORT_ELEVATIONS';

export const LIST_FLOORS = 'LIST_FLOORS';
export const SAVE_FLOOR = 'SAVE_FLOOR';
export const DELETE_FLOOR = 'DELETE_FLOOR';
export const SORT_FLOORS = 'SORT_FLOORS';
export const SET_FLOOR_GROUPS = 'SET_FLOOR_GROUPS';
export const SET_FLOOR_OPTIONS = 'SET_FLOOR_OPTIONS';
export const SET_RELOAD_FLOORS = 'SET_RELOAD_FLOORS';

export const SAVE_FLOOR_GROUP = 'SAVE_FLOOR_GROUP';
export const DELETE_FLOOR_GROUP = 'DELETE_FLOOR_GROUP';
export const SORT_FLOOR_GROUPS = 'SORT_FLOOR_GROUPS';

export const SAVE_FLOOR_OPTION = 'SAVE_FLOOR_OPTION';
export const DELETE_FLOOR_OPTION = 'DELETE_FLOOR_OPTION';

export const GET_SPECIFICATIONS = 'GET_SPECIFICATIONS';
export const SAVE_SPECIFICATION = 'SAVE_SPECIFICATION';
export const DELETE_SPECIFICATION = 'DELETE_SPECIFICATION';

export const GET_PALETTES = 'GET_PALETTES';
export const GET_PALETTE = 'GET_PALETTE';
export const CLEAN_PALETTES = 'CLEAN_PALETTES';
export const SAVE_PALETTE = 'SAVE_PALETTE';
export const DELETE_PALETTE = 'DELETE_PALETTE';

export const GET_FORMS = 'GET_FORMS';
export const GET_FORM = 'GET_FORM';
export const CLEAN_FORMS = 'CLEAN_FORMS';
export const SAVE_FORM = 'SAVE_FORM';
export const DELETE_FORM = 'DELETE_FORM';

export const LIST_TRACKING_PAGES = 'LIST_TRACKING_PAGES';
export const GET_TRACKING_PAGE = 'GET_TRACKING_PAGE';
export const CLEAN_TRACKING_PAGES = 'CLEAN_TRACKING_PAGES';
export const SAVE_TRACKING_PAGE = 'SAVE_TRACKING_PAGE';
export const DELETE_TRACKING_PAGE = 'DELETE_TRACKING_PAGE';

export const LIST_CITIES = 'LIST_CITIES';
export const GET_CITY = 'GET_CITY';
export const CLEAN_CITIES = 'CLEAN_CITIES';
export const SAVE_CITY = 'SAVE_CITY';
export const DELETE_CITY = 'DELETE_CITY';

export const LIST_TEAMS = 'LIST_TEAMS';
export const GET_TEAM = 'GET_TEAM';
export const CLEAN_TEAMS = 'CLEAN_TEAMS';
export const SAVE_TEAM = 'SAVE_TEAM';
export const DELETE_TEAM = 'DELETE_TEAM';
export const SET_RELOAD_TEAMS = 'SET_RELOAD_TEAMS';

export const LIST_TEAM_USERS = 'LIST_TEAM_USERS';
export const GET_TEAM_USER = 'GET_TEAM_USER';
export const CLEAN_TEAM_USERS = 'CLEAN_TEAM_USERS';
export const SAVE_TEAM_USER = 'SAVE_TEAM_USER';
export const DELETE_TEAM_USER = 'DELETE_TEAM_USER';
export const SET_RELOAD_TEAM_USERS = 'SET_RELOAD_TEAM_USERS';

export const LIST_WEBHOOKS = 'LIST_WEBHOOKS';
export const GET_WEBHOOK = 'GET_WEBHOOK';
export const CLEAN_WEBHOOKS = 'CLEAN_WEBHOOKS';
export const SAVE_WEBHOOK = 'SAVE_WEBHOOK';
export const DELETE_WEBHOOK = 'DELETE_WEBHOOK';

export const LIST_WEBHOOK_DELIVERIES = 'LIST_WEBHOOK_DELIVERIES';
export const GET_WEBHOOK_DELIVERY = 'GET_WEBHOOK_DELIVERY';
export const CLEAN_WEBHOOK_DELIVERIES = 'CLEAN_WEBHOOK_DELIVERIES';
export const DELETE_WEBHOOK_DELIVERY = 'DELETE_WEBHOOK_DELIVERY';
export const SET_WEBHOOK_DELIVERY = 'SET_WEBHOOK_DELIVERY';

export const GET_COMMUNITY_PRODUCT_TYPES = 'GET_COMMUNITY_PRODUCT_TYPES';
export const GET_COMMUNITY_PRODUCT_TYPE = 'GET_COMMUNITY_PRODUCT_TYPE';
export const SAVE_COMMUNITY_PRODUCT_TYPE = 'SAVE_COMMUNITY_PRODUCT_TYPE';
export const DELETE_COMMUNITY_PRODUCT_TYPE = 'DELETE_COMMUNITY_PRODUCT_TYPE';
export const SET_COMMUNITY_PRODUCT_TYPES = 'SET_COMMUNITY_PRODUCT_TYPES';
export const SORT_COMMUNITY_PRODUCT_TYPES = 'SORT_COMMUNITY_PRODUCT_TYPES';

export const SET_MODEL_COMMUNITIES = 'SET_MODEL_COMMUNITIES';
export const GET_COMMUNITY_MODELS = 'GET_COMMUNITY_MODELS';
export const SORT_COMMUNITY_MODELS = 'SORT_COMMUNITY_MODELS';
export const SET_COMMUNITY_MODELS = 'SET_COMMUNITY_MODELS';
export const SAVE_COMMUNITY_MODEL = 'SAVE_COMMUNITY_MODEL';
export const UPDATE_COMMUNITY_MODEL = 'UPDATE_COMMUNITY_MODEL';
export const DELETE_COMMUNITY_MODEL = 'DELETE_COMMUNITY_MODEL';
export const SET_MODELS_SEARCH_FILTERS = 'SET_MODELS_SEARCH_FILTERS';
export const SET_MODELS_VIEW_MODE = 'SET_MODELS_VIEW_MODE';

export const GET_PACKAGES = 'GET_PACKAGES';
export const GET_PACKAGE = 'GET_PACKAGE';
export const SAVE_PACKAGE = 'SAVE_PACKAGE';
export const DELETE_PACKAGE = 'DELETE_PACKAGE';
export const CLEAN_PACKAGES = 'CLEAN_PACKAGES';

export const GET_DOCUMENTS = 'GET_DOCUMENTS';
export const SAVE_DOCUMENT = 'SAVE_DOCUMENT';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const ADD_DOCUMENT_FILE = 'ADD_DOCUMENT_FILE';
export const REMOVE_DOCUMENT_FILE = 'REMOVE_DOCUMENT_FILE';
export const CLEAN_DOCUMENT_FILES = 'CLEAN_DOCUMENT_FILES';

export const GET_MODEL_IMAGES = 'GET_MODEL_IMAGES';
export const SAVE_MODEL_IMAGE = 'SAVE_MODEL_IMAGE';
export const DELETE_MODEL_IMAGE = 'DELETE_MODEL_IMAGE';
export const SET_MODEL_IMAGES = 'SET_MODEL_IMAGES';
export const SORT_MODEL_IMAGES = 'SORT_MODEL_IMAGES';

export const GET_USERS = 'GET_USERS';
export const GET_USER = 'GET_USER';
export const SAVE_USER = 'SAVE_USER';
export const DELETE_USER = 'DELETE_USER';
export const CLEAN_USERS = 'CLEAN_USERS';
export const GET_PROFILE = 'GET_PROFILE';
export const SET_RELOAD_USERS = 'SET_RELOAD_USERS';

export const SAVE_CUSTOMER = 'SAVE_CUSTOMER';
export const GET_CUSTOMER = 'GET_CUSTOMER';
export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const CLEAN_CUSTOMERS = 'CLEAN_CUSTOMERS';
export const SYNC_CUSTOMER_PROPERTY_INFO = 'SYNC_CUSTOMER_PROPERTY_INFO';
export const RESET_CUSTOMER_VERIFICATIONS = 'RESET_CUSTOMER_VERIFICATIONS';
export const SET_CUSTOMER_ATTRIBUTES = 'SET_CUSTOMER_ATTRIBUTES';
export const SET_CUSTOMERS_SORT_FIELD = 'SET_CUSTOMERS_SORT_FIELD';
export const SET_CUSTOMERS_SORT_ORDER = 'SET_CUSTOMERS_SORT_ORDER';
export const SET_CUSTOMER_FILTERS = 'SET_CUSTOMER_FILTERS';
export const SET_CUSTOMER_COLUMNS = 'SET_CUSTOMER_COLUMNS';
export const SET_CUSTOMER_INTEREST_FILTERS = 'SET_CUSTOMER_INTEREST_FILTERS';
export const GET_CUSTOMER_FINANCIAL_REPORT = 'GET_CUSTOMER_FINANCIAL_REPORT';
export const SHOW_CUSTOMER_FORM = 'SHOW_CUSTOMER_FORM';
export const SET_ACTIVE_CUSTOMER_TAB = 'SET_ACTIVE_CUSTOMER_TAB';

export const SET_INVITED_CUSTOMERS = 'SET_INVITED_CUSTOMERS';
export const SET_CUSTOMERS_INVITE_METHOD = 'SET_CUSTOMERS_INVITE_METHOD';
export const SET_INVITED_HOME = 'SET_INVITED_HOME';
export const SET_INVITATION_TYPE = 'SET_INVITATION_TYPE';
export const SET_HOMES_VIEW_MODE = 'SET_HOMES_VIEW_MODE';

export const GET_PRODUCT_TYPES = 'GET_PRODUCT_TYPES';
export const GET_PRODUCT_TYPE = 'GET_PRODUCT_TYPE';
export const SAVE_PRODUCT_TYPE = 'SAVE_PRODUCT_TYPE';
export const DELETE_PRODUCT_TYPE = 'DELETE_PRODUCT_TYPE';
export const SET_PRODUCT_TYPES = 'SET_PRODUCT_TYPES';
export const SET_PRODUCT_TYPES_VIEW_MODE = 'SET_PRODUCT_TYPES_VIEW_MODE';

export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_COMPANY = 'GET_COMPANY';
export const SET_CURRENT_COMPANY = 'SET_CURRENT_COMPANY';
export const SAVE_COMPANY = 'SAVE_COMPANY';
export const ARCHIVE_COMPANY = 'ARCHIVE_COMPANY';
export const RESTORE_COMPANY = 'RESTORE_COMPANY';
export const CLEAN_COMPANIES = 'CLEAN_COMPANIES';

export const SET_IMPORTED_DATA = 'SET_IMPORTED_DATA';
export const CLEAN_IMPORTED_DATA = 'CLEAN_IMPORTED_DATA';

export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_PROJECT = 'GET_PROJECT';
export const CLEAN_PROJECTS = 'CLEAN_PROJECTS';
export const SAVE_PROJECT = 'SAVE_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const SET_PROJECTS_VIEW_MODE = 'SET_PROJECTS_VIEW_MODE';

export const RELOAD_PROJECT_PALETTES = 'RELOAD_PROJECT_PALETTES';

export const RELOAD_PROJECT_PACKAGES = 'RELOAD_PROJECT_PACKAGES';

export const RELOAD_PROJECT_BUILDINGS = 'RELOAD_PROJECT_BUILDINGS';
export const SET_PROJECT_BUILDINGS_VIEW_MODE = 'SET_PROJECT_BUILDINGS_VIEW_MODE';

export const GET_BUILDING_MODELS = 'GET_BUILDING_MODELS';
export const GET_BUILDING_MODEL = 'GET_BUILDING_MODEL';
export const SAVE_BUILDING_MODEL = 'SAVE_BUILDING_MODEL';
export const DELETE_BUILDING_MODEL = 'DELETE_BUILDING_MODEL';
export const SET_BUILDING_MODELS_VIEW_MODE = 'SET_BUILDING_MODELS_VIEW_MODE';

export const GET_UNIT = 'GET_UNIT';
export const GET_UNITS = 'GET_UNITS';
export const SAVE_UNIT = 'SAVE_UNIT';
export const CLEAN_UNITS = 'CLEAN_UNITS';
export const DELETE_UNIT = 'DELETE_UNIT';
export const SET_UNIT_VIEW_MODE = 'SET_UNIT_VIEW_MODE';
export const SET_UNIT_COLUMNS = 'SET_UNIT_COLUMNS';
export const SET_UNIT_FILTERS = 'SET_UNIT_FILTERS';

export const GET_OPTIONS = 'GET_OPTIONS';
export const GET_OPTION = 'GET_OPTION';
export const SAVE_OPTION = 'SAVE_OPTION';
export const DELETE_OPTION = 'DELETE_OPTION';
export const GET_OPTION_TYPES = 'GET_OPTION_TYPES';
export const GET_OPTION_TYPE = 'GET_OPTION_TYPE';
export const SAVE_OPTION_TYPE = 'SAVE_OPTION_TYPE';
export const DELETE_OPTION_TYPE = 'DELETE_OPTION_TYPE';

export const GET_BUILDING_FLOOR = 'GET_BUILDING_FLOOR';
export const GET_BUILDING_FLOORS = 'GET_BUILDING_FLOORS';
export const SAVE_BUILDING_FLOOR = 'SAVE_BUILDING_FLOOR';
export const DELETE_BUILDING_FLOOR = 'DELETE_BUILDING_FLOOR';
export const SET_BUILDING_FLOOR_UNITS = 'SET_BUILDING_FLOOR_UNITS';
export const SORT_BUILDING_FLOORS = 'SORT_BUILDING_FLOORS';
export const SET_RELOAD_BUILDING_FLOOR_UNITS = 'SET_RELOAD_BUILDING_FLOOR_UNITS';

export const SET_REPORT_COMMUNITY = 'SET_REPORT_COMMUNITY';
export const SET_REPORT_MODEL = 'SET_REPORT_MODEL';
export const SET_REPORT_BUILDING_MODEL = 'SET_REPORT_BUILDING_MODEL';
export const SET_REPORT_PROJECT = 'SET_REPORT_PROJECT';
export const CLEAN_REPORT_ITEMS = 'CLEAN_REPORT_ITEMS';

export const GET_OFFERS = 'GET_OFFERS';
export const GET_OFFER = 'GET_OFFER';
export const SAVE_OFFER = 'SAVE_OFFER';
export const DELETE_OFFER = 'DELETE_OFFER';
export const SET_OFFER_FILTERS = 'SET_OFFER_FILTERS';

export const GET_HOME = 'GET_HOME';
export const LIST_HOMES = 'LIST_HOMES';
export const SET_RELOAD_HOMES = 'SET_RELOAD_HOMES';
export const SET_HOME_ATTRIBUTES = 'SET_HOME_ATTRIBUTES';
export const DELETE_HOME = 'DELETE_HOME';
export const CLEAN_HOMES = 'CLEAN_HOMES';

export const GET_QUICK_POSSESSIONS = 'GET_QUICK_POSSESSIONS';
export const SAVE_QUICK_POSSESSION = 'SAVE_QUICK_POSSESSION';
export const GET_QUICK_POSSESSION = 'GET_QUICK_POSSESSION';
export const DELETE_QUICK_POSSESSION = 'DELETE_QUICK_POSSESSION';
export const SET_QUICK_POSSESSION_VIEW_MODE = 'SET_QUICK_POSSESSION_VIEW_MODE';
export const SET_RELOAD_QUICK_POSSESSIONS = 'SET_RELOAD_QUICK_POSSESSIONS';
export const CLEAN_QUICK_POSSESSIONS = 'CLEAN_QUICK_POSSESSIONS';
export const SET_QUICK_POSSESSION_COLUMNS = 'SET_QUICK_POSSESSION_COLUMNS';
export const SET_QUICK_POSSESSION_FILTERS = 'SET_QUICK_POSSESSION_FILTERS';

export const GET_EXTERNAL_DATA_SOURCES = 'GET_EXTERNAL_DATA_SOURCES';
export const GET_EXTERNAL_DATA_SOURCE = 'GET_EXTERNAL_DATA_SOURCE';
export const SAVE_EXTERNAL_DATA_SOURCE = 'SAVE_EXTERNAL_DATA_SOURCE';
export const DELETE_EXTERNAL_DATA_SOURCE = 'DELETE_EXTERNAL_DATA_SOURCE';
export const SYNC_EXTERNAL_DATA_SOURCE = 'SYNC_EXTERNAL_DATA_SOURCE';
export const GET_BUILT_OPTIONS_REPORT = 'GET_BUILT_OPTIONS_REPORT';

export const GET_DEPOSIT = 'GET_DEPOSIT';
export const GET_DEPOSITS = 'GET_DEPOSITS';
export const SAVE_DEPOSIT = 'SAVE_DEPOSIT';
export const ADD_OR_UPDATE_DEPOSIT = 'ADD_OR_UPDATE_DEPOSIT';

export const GET_TOURS = 'GET_TOURS';
export const GET_TOUR = 'GET_TOUR';

export const GET_ACTIVITIES = 'GET_ACTIVITIES';
export const CLEAN_ACTIVITIES = 'CLEAN_ACTIVITIES';

export const LIST_MODEL_PACKAGES = 'LIST_MODEL_PACKAGES';
export const SAVE_MODEL_PACKAGE = 'SAVE_MODEL_PACKAGE';
export const DELETE_MODEL_PACKAGE = 'DELETE_MODEL_PACKAGE';
export const RELOAD_MODEL_PACKAGES = 'RELOAD_MODEL_PACKAGES';

export const SAVE_TAX_RATE = 'SAVE_TAX_RATE';
export const GET_TAX_RATE = 'GET_TAX_RATE';
export const GET_TAX_RATES = 'GET_TAX_RATES';
export const DELETE_TAX_RATE = 'DELETE_TAX_RATE';

export const LIST_CLIENT_MESSAGES = 'LIST_CLIENT_MESSAGES';
export const SAVE_CLIENT_MESSAGE = 'SAVE_CLIENT_MESSAGE';

export const SAVE_BUILDING_MODEL_PACKAGE = 'SAVE_BUILDING_MODEL_PACKAGE';
export const LIST_BUILDING_MODEL_PACKAGES = 'LIST_BUILDING_MODEL_PACKAGES';
export const RELOAD_BUILDING_MODEL_PACKAGES = 'RELOAD_BUILDING_MODEL_PACKAGES';
export const DELETE_BUILDING_MODEL_PACKAGE = 'DELETE_BUILDING_MODEL_PACKAGE';

export const SAVE_BUILDING_MODEL_PALETTE = 'SAVE_BUILDING_MODEL_PALETTE';
export const LIST_BUILDING_MODEL_PALETTES = 'LIST_BUILDING_MODEL_PALETTES';
export const RELOAD_BUILDING_MODEL_PALETTES = 'RELOAD_BUILDING_MODEL_PALETTES';
export const DELETE_BUILDING_MODEL_PALETTE = 'DELETE_BUILDING_MODEL_PALETTE';

export const SAVE_PRE_APPROVAL = 'SAVE_PRE_APPROVAL';

export const LIST_PARTY_CONTACTS = 'LIST_PARTY_CONTACTS';
export const CLEAN_PARTY_CONTACTS = 'CLEAN_PARTY_CONTACTS';

export const GET_SELLER_SPACE = 'GET_SELLER_SPACE';
export const GET_SELLER_SPACES = 'GET_SELLER_SPACES';
export const SAVE_SELLER_SPACE = 'SAVE_SELLER_SPACE';
export const DELETE_SELLER_SPACE = 'DELETE_SELLER_SPACE';

export const CLEAN_PRICE_RULES = 'CLEAN_PRICE_RULES';
export const ADD_PRICE_RULES = 'ADD_PRICE_RULES';
export const REMOVE_PRICE_RULE = 'REMOVE_PRICE_RULE';

export const LIST_MODEL_PALETTES = 'LIST_MODEL_PALETTES';
export const SAVE_MODEL_PALETTE = 'SAVE_MODEL_PALETTE';
export const DELETE_MODEL_PALETTE = 'DELETE_MODEL_PALETTE';
export const SET_RELOAD_MODEL_PALETTES = 'SET_RELOAD_MODEL_PALETTES';

export const ADD_ITEM_TO_NAVIGATION = 'ADD_ITEM_TO_NAVIGATION';
export const RESET_NAVIGATION = 'RESET_NAVIGATION';

export const SENDGRID_LIST_CONTACT_LISTS = 'SENDGRID_LIST_CONTACT_LISTS';
export const SENDGRID_CLEAN_CONTACT_LISTS = 'SENDGRID_CLEAN_CONTACT_LISTS';
export const SENDGRID_GET_CONTACT_LIST = 'SENDGRID_GET_CONTACT_LIST';
export const SENDGRID_SAVE_CONTACT_LIST = 'SENDGRID_SAVE_CONTACT_LIST';
export const SENDGRID_DELETE_CONTACT_LIST = 'SENDGRID_DELETE_CONTACT_LIST';
export const SENDGRID_SET_CONTACT_LIST = 'SENDGRID_SET_CONTACT_LIST';
export const SENDGRID_SET_CONTACTS = 'SENDGRID_SET_CONTACTS';
export const SENDGRID_CLEAN_CONTACTS = 'SENDGRID_CLEAN_CONTACTS';
export const SENDGRID_SAVE_CONTACT = 'SENDGRID_SAVE_CONTACT';
export const SENDGRID_DELETE_CONTACT_FROM_LIST = 'SENDGRID_DELETE_CONTACT_FROM_LIST';

export const LIST_PROPERTY_TAX_RATES = 'LIST_PROPERTY_TAX_RATES';
export const GET_PROPERTY_TAX_RATE = 'GET_PROPERTY_TAX_RATE';
export const SAVE_PROPERTY_TAX_RATE = 'SAVE_PROPERTY_TAX_RATE';
export const DELETE_PROPERTY_TAX_RATE = 'DELETE_PROPERTY_TAX_RATE';

export const SET_SUB_MENU = 'SET_SUB_MENU';
export const SET_LEVEL_TWO_SUB_MENU = 'SET_LEVEL_TWO_SUB_MENU';

export const GET_RESERVE_NOW_WORKFLOW = 'GET_RESERVE_NOW_WORKFLOW';
export const GET_VERIFIED_WORKFLOW = 'GET_VERIFIED_WORKFLOW';

export const GET_COMPANY_WORKFLOW = 'GET_COMPANY_WORKFLOW';
export const SAVE_COMPANY_WORKFLOW = 'SAVE_COMPANY_WORKFLOW';

export const LIST_DEALS = 'LIST_DEALS';
export const CLEAN_DEALS = 'CLEAN_DEALS';

export const LIST_MORTGAGE_PROVIDERS = 'LIST_MORTGAGE_PROVIDERS';
export const GET_MORTGAGE_PROVIDER = 'GET_MORTGAGE_PROVIDER';
export const CLEAN_MORTGAGE_PROVIDERS = 'CLEAN_MORTGAGE_PROVIDERS';
export const SAVE_MORTGAGE_PROVIDER = 'SAVE_MORTGAGE_PROVIDER';
export const DELETE_MORTGAGE_PROVIDER = 'DELETE_MORTGAGE_PROVIDER';
export const SET_RELOAD_MORTGAGE_PROVIDERS = 'SET_RELOAD_MORTGAGE_PROVIDERS';
