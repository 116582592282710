import {
  START,
  SUCCESS,
  ERROR,
  GET_EXTERNAL_DATA_SOURCES,
  GET_EXTERNAL_DATA_SOURCE,
  SAVE_EXTERNAL_DATA_SOURCE,
  DELETE_EXTERNAL_DATA_SOURCE,
  SYNC_EXTERNAL_DATA_SOURCE,
  GET_BUILT_OPTIONS_REPORT,
} from '../actions/types';
import { getError } from '../../utils/requestUtils';
import { addItemIfNotExists } from '../../utils/arrayUtils';

const INITIAL_STATE = {
  externalDataSources: [],
  externalDataSource: null,

  getExternalDataSources: {},
  getExternalDataSource: {},
  saveExternalDataSource: {},
  deleteExternalDataSource: {},
  getBuiltOptionsReport: {},
  syncExternalDataSource: {},
  syncPriceRules: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action?.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case GET_EXTERNAL_DATA_SOURCE + START:
      return { ...state, getExternalDataSource: { loading: true }, externalDataSource: null };
    case GET_EXTERNAL_DATA_SOURCE + SUCCESS:
      return { ...state, getExternalDataSource: { loading: false }, externalDataSource: data.item };
    case GET_EXTERNAL_DATA_SOURCE + ERROR:
      return { ...state, getExternalDataSource: { loading: false, error } };

    case GET_EXTERNAL_DATA_SOURCES + START:
      return { ...state, getExternalDataSources: { loading: true } };
    case GET_EXTERNAL_DATA_SOURCES + SUCCESS:
      return {
        ...state,
        getExternalDataSources: { loading: false },
        externalDataSources: data.items
      };
    case GET_EXTERNAL_DATA_SOURCES + ERROR:
      return { ...state, getExternalDataSources: { loading: false, error } };

    case SAVE_EXTERNAL_DATA_SOURCE + START:
      return { ...state, saveExternalDataSource: { loading: false } };
    case SAVE_EXTERNAL_DATA_SOURCE + SUCCESS:
      return {
        ...state,
        saveExternalDataSource: { loading: false },
        externalDataSource: data.item
      };
    case SAVE_EXTERNAL_DATA_SOURCE + ERROR:
      return { ...state, saveExternalDataSource: { loading: false, error } };

    case DELETE_EXTERNAL_DATA_SOURCE + START:
      return { ...state, deleteExternalDataSource: { loading: true } };
    case DELETE_EXTERNAL_DATA_SOURCE + SUCCESS:
      return {
        ...state,
        deleteExternalDataSource: { loading: false },
        externalDataSource: data.item,
        externalDataSources: state.externalDataSources.filter((eds) => eds.id !== data.item.id)
      };
    case DELETE_EXTERNAL_DATA_SOURCE + ERROR:
      return { ...state, deleteExternalDataSource: { loading: false, error } };

    case SYNC_EXTERNAL_DATA_SOURCE + START:
      return { ...state, syncExternalDataSource: { loading: true } };
    case SYNC_EXTERNAL_DATA_SOURCE + SUCCESS:
      return {
        ...state,
        syncExternalDataSource: { loading: false },
        externalDataSources: addItemIfNotExists(state.externalDataSources, data.item)
      };
    case SYNC_EXTERNAL_DATA_SOURCE + ERROR:
      return { ...state, syncExternalDataSource: { loading: false, error } };

    case GET_BUILT_OPTIONS_REPORT + START:
      return { ...state, getBuiltOptionsReport: { loading: true } };
    case GET_BUILT_OPTIONS_REPORT + SUCCESS:
      return {
        ...state,
        syncExternalDataSource: { loading: false },
        externalDataSources: addItemIfNotExists(state.externalDataSources, data.item)
      };
    case GET_BUILT_OPTIONS_REPORT + ERROR:
      return { ...state, getBuiltOptionsReport: { loading: false, error } };

    default:
      return { ...state };
  }
}
