import {
  RELOAD_PROJECT_PACKAGES
} from '../actions/types';

const INITIAL_STATE = {
  reload: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RELOAD_PROJECT_PACKAGES:
      return { ...state, reload: action.payload.reload };

    default:
      return { ...state };
  }
}
