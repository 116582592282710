import {
  START,
  SUCCESS,
  ERROR,
  SAVE_TEAM_USER,
  DELETE_TEAM_USER,
  LIST_TEAM_USERS,
  CLEAN_TEAM_USERS,
  GET_TEAM_USER,
  SET_RELOAD_TEAM_USERS
} from '../actions/types';
import { getError } from '../../utils/requestUtils';

const INITIAL_STATE = {
  teamUsers: [],
  teamUser: null,
  reloadTeamUsers: false,

  listTeamUsers: {},
  getTeamUser: {},
  saveTeamUser: {},
  deleteTeamUser: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case LIST_TEAM_USERS + START:
      return { ...state, listTeamUsers: { loading: true } };
    case LIST_TEAM_USERS + SUCCESS:
      return {
        ...state,
        listTeamUsers: { loading: false },
        teamUsers: data.items
      };
    case LIST_TEAM_USERS + ERROR:
      return { ...state, listTeamUsers: { loading: false, error } };

    case CLEAN_TEAM_USERS + SUCCESS:
      return {
        ...state,
        listTeamUsers: { loading: false },
        teamUsers: [],
        ...action.payload
      };

    case GET_TEAM_USER + START:
      return { ...state, getTeamUser: { loading: true } };
    case GET_TEAM_USER + SUCCESS:
      return { ...state, getTeamUser: { loading: false }, teamUser: data.item };
    case GET_TEAM_USER + ERROR:
      return { ...state, getTeamUser: { loading: false, error } };

    case SAVE_TEAM_USER + START:
      return { ...state, saveTeamUser: { loading: true } };
    case SAVE_TEAM_USER + SUCCESS:
      return { ...state, saveTeamUser: { loading: false }, teamUser: data.item };
    case SAVE_TEAM_USER + ERROR:
      return { ...state, saveTeamUser: { loading: false, error } };

    case DELETE_TEAM_USER + START:
      return { ...state, deleteTeamUser: { loading: true } };
    case DELETE_TEAM_USER + SUCCESS:
      return {
        ...state,
        deleteTeamUser: { loading: false },
        teamUsers: state.teamUsers.filter((s) => s.id !== data.item.id)
      };
    case DELETE_TEAM_USER + ERROR:
      return { ...state, deleteTeamUser: { loading: false, error } };

    case SET_RELOAD_TEAM_USERS:
      return { ...state, reloadTeamUsers: action.payload.reload };

    default:
      return { ...state };
  }
}
