import {
  LIST_CLIENT_MESSAGES, SAVE_CLIENT_MESSAGE, START, SUCCESS, ERROR
} from '../actions/types';
import { getError } from '../../utils/requestUtils';
import { addItemIfNotExists } from '../../utils/arrayUtils';

const INITIAL_STATE = {
  messages: [],
  pagination: null,

  getMessages: {},
  saveClientMessage: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case LIST_CLIENT_MESSAGES + START:
      return { ...state, getMessages: { loading: true } };
    case LIST_CLIENT_MESSAGES + SUCCESS:
      return {
        ...state,
        getMessages: { loading: false },
        messages: data.result.items,
        pagination: data.result.pagination
      };
    case LIST_CLIENT_MESSAGES + ERROR:
      return { ...state, getMessages: { loading: false, error } };

    case SAVE_CLIENT_MESSAGE + START:
      return { ...state, saveClientMessage: { loading: true } };
    case SAVE_CLIENT_MESSAGE + SUCCESS:
      return {
        ...state,
        saveClientMessage: { loading: false },
        messages: addItemIfNotExists(state.messages, data.item)
      };
    case SAVE_CLIENT_MESSAGE + ERROR:
      return { ...state, saveClientMessage: { loading: false, error } };

    default:
      return { ...state };
  }
}
