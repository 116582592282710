import { getError } from '../../utils/requestUtils';
import {
  LIST_DEALS, START, SUCCESS, ERROR, CLEAN_DEALS
} from '../actions/types';

const INITIAL_STATE = {
  deals: [],
  pagination: null,

  listDeals: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case LIST_DEALS + START:
      return { ...state, listDeals: { loading: true } };
    case LIST_DEALS + SUCCESS:
      return {
        ...state,
        listDeals: { loading: false },
        deals: data.result.items,
        pagination: data.result.pagination
      };
    case LIST_DEALS + ERROR:
      return { ...state, listDeals: { loading: false, error } };

    case CLEAN_DEALS + SUCCESS:
      return {
        ...state,
        listDeals: { loading: false },
        deals: [],
        pagination: null,
        ...action.payload
      };

    default:
      return { ...state };
  }
}
