import {
  START, SUCCESS, ERROR,
  SAVE_FLOOR_OPTION, DELETE_FLOOR_OPTION
} from '../actions/types';
import { getError } from '../../utils/requestUtils';

const INITIAL_STATE = {
  floorOption: null,

  saveFloorOption: {},
  deleteFloorOption: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case SAVE_FLOOR_OPTION + START:
      return { ...state, saveFloorOption: { loading: true } };
    case SAVE_FLOOR_OPTION + SUCCESS:
      return { ...state, saveFloorOption: { loading: false }, floorOption: data.item };
    case SAVE_FLOOR_OPTION + ERROR:
      return { ...state, saveFloorOption: { loading: false, error } };

    case DELETE_FLOOR_OPTION + START:
      return { ...state, deleteFloorOption: { loading: true } };
    case DELETE_FLOOR_OPTION + SUCCESS:
      return { ...state, deleteFloorOption: { loading: false } };
    case DELETE_FLOOR_OPTION + ERROR:
      return { ...state, deleteFloorOption: { loading: false, error } };

    default:
      return { ...state };
  }
}
