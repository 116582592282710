/**
 * Add item to array if it doesn't exist
 * @param items
 * @param item
 * @param keyToSearch
 * @returns {[]}
 */
export function addItemIfNotExists(items, item, keyToSearch = 'id') {
  const newItems = [...items];
  const index = newItems.findIndex((i) => i[keyToSearch] === item[keyToSearch]);
  if (index === -1) newItems.push(item);
  else newItems[index] = item;
  return newItems;
}

/**
 * Remove item by index
 * @param items
 * @param i
 * @return {[]}
 */
export function removeItem(items, i) {
  return items.slice(0, i).concat(items.slice(i + 1, items.length));
}

/**
 * Remove item if related model is null
 * @param items
 * @param modelName
 * @return {[]}
 */
export function removeRelatedNullObject(items, modelName) {
  return items.filter((i) => !!i[modelName]);
}

/**
 * Returns formatted items with a comma
 * @param items
 * @return {[]}
 */
export function getFormattedStringArray(items) {
  if (!items && !items.length > 0) return [];
  return items.join(', ');
}

/**
 * Returns true if array length is greater than 0
 * @param array
 * @return {boolean}
 */
export function isValidLength(array) {
  return !!(array && array.length > 0);
}

/**
 * Returns true if value exists in array
 * @param array
 * @param value
 * @return {boolean}
 */
export function valueExistsInArray(array, value) {
  return array.indexOf(value) !== -1;
}
