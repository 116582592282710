import {
  START, SUCCESS, ERROR, GET_TOURS, GET_TOUR
} from '../actions/types';
import { getError } from '../../utils/requestUtils';

const INITIAL_STATE = {
  tours: [],
  tour: null,

  getTours: {},
  getTour: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case GET_TOURS + START:
      return { ...state, getTours: { loading: true } };
    case GET_TOURS + SUCCESS:
      return { ...state, getTours: { loading: false }, tours: data.items };
    case GET_TOURS + ERROR:
      return { ...state, getTours: { loading: false, error } };

    case GET_TOUR + START:
      return { ...state, getTour: { loading: true } };
    case GET_TOUR + SUCCESS:
      return { ...state, getTour: { loading: false }, tour: data.item };
    case GET_TOUR + ERROR:
      return { ...state, getTour: { loading: false, error } };

    default:
      return { ...state };
  }
}
