import { getError } from '../../utils/requestUtils';
import {
  SAVE_PRE_APPROVAL, START, SUCCESS, ERROR
} from '../actions/types';

const INITIAL_STATE = {
  preApproval: null,

  savePreApproval: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case SAVE_PRE_APPROVAL + START:
      return { ...state, savePreApproval: { loading: true } };
    case SAVE_PRE_APPROVAL + SUCCESS:
      return {
        ...state,
        savePreApproval: { loading: false },
        preApproval: data.item
      };
    case SAVE_PRE_APPROVAL + ERROR:
      return { ...state, savePreApproval: { loading: false, error } };

    default:
      return { ...state };
  }
}
