import {
  START, SUCCESS, ERROR,
  SAVE_PACKAGE, DELETE_PACKAGE, GET_PACKAGES, CLEAN_PACKAGES, GET_PALETTE, GET_PACKAGE
} from '../actions/types';
import { getError } from '../../utils/requestUtils';

const INITIAL_STATE = {
  packages: [],
  package: null,

  getPackage: {},
  getPackages: {},
  savePackage: {},
  deletePackage: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case GET_PACKAGES + START:
      return { ...state, getPackages: { loading: true } };
    case GET_PACKAGES + SUCCESS:
      return {
        ...state,
        getPackages: { loading: false },
        packages: data.items
      };
    case GET_PACKAGES + ERROR:
      return { ...state, getPackages: { loading: false, error } };

    case CLEAN_PACKAGES + SUCCESS:
      return {
        ...state,
        getPackages: { loading: false },
        packages: [],
        ...action.payload
      };

    case GET_PACKAGE + START:
      return { ...state, getPackage: { loading: true } };
    case GET_PACKAGE + SUCCESS:
      return { ...state, getPackage: { loading: false }, package: data.item };
    case GET_PACKAGE + ERROR:
      return { ...state, getPackage: { loading: false, error } };

    case SAVE_PACKAGE + START:
      return { ...state, savePackage: { loading: true } };
    case SAVE_PACKAGE + SUCCESS:
      return { ...state, savePackage: { loading: false }, package: data.item };
    case SAVE_PACKAGE + ERROR:
      return { ...state, savePackage: { loading: false, error } };

    case DELETE_PACKAGE + START:
      return { ...state, deletePackage: { loading: true } };
    case DELETE_PACKAGE + SUCCESS:
      return {
        ...state,
        deletePackage: { loading: false },
        packages: state.packages.filter((s) => s.id !== data.item.id)
      };
    case DELETE_PACKAGE + ERROR:
      return { ...state, deletePackage: { loading: false, error } };

    default:
      return { ...state };
  }
}
