import {
  START,
  SUCCESS,
  ERROR,
  GET_BUILDING_FLOORS,
  GET_BUILDING_FLOOR,
  SAVE_BUILDING_FLOOR,
  DELETE_BUILDING_FLOOR,
  SET_BUILDING_FLOOR_UNITS,
  SORT_BUILDING_FLOORS,
  SET_RELOAD_BUILDING_FLOOR_UNITS
} from '../actions/types';
import { getError } from '../../utils/requestUtils';
import { addItemIfNotExists } from '../../utils/arrayUtils';

const INITIAL_STATE = {
  buildingFloors: [],
  buildingFloor: null,
  reloadUnits: false,

  getBuildingFloors: {},
  saveBuildingFloor: {},
  deleteBuildingFloor: {},
  setBuildingFloorUnits: {},
  sortBuildingFloors: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case GET_BUILDING_FLOORS + START:
      return { ...state, getBuildingFloors: { loading: true } };
    case GET_BUILDING_FLOORS + SUCCESS:
      return { ...state, getBuildingFloors: { loading: false }, buildingFloors: data.items };
    case GET_BUILDING_FLOORS + ERROR:
      return { ...state, getBuildingFloors: { loading: false, error } };

    case GET_BUILDING_FLOOR + START:
      return { ...state, getBuildingFloor: { loading: true } };
    case GET_BUILDING_FLOOR + SUCCESS:
      return { ...state, getBuildingFloor: { loading: false }, buildingFloor: data.item };
    case GET_BUILDING_FLOOR + ERROR:
      return { ...state, getBuildingFloor: { loading: false, error } };

    case SAVE_BUILDING_FLOOR + START:
      return { ...state, saveBuildingFloor: { loading: true } };
    case SAVE_BUILDING_FLOOR + SUCCESS:
      return {
        ...state,
        saveBuildingFloor: { loading: false },
        buildingFloor: data.item,
        buildingFloors: addItemIfNotExists(state.buildingFloors, data.item)
      };
    case SAVE_BUILDING_FLOOR + ERROR:
      return { ...state, saveBuildingFloor: { loading: false, error } };

    case SET_BUILDING_FLOOR_UNITS + START:
      return { ...state, setBuildingFloorUnits: { loading: false } };
    case SET_BUILDING_FLOOR_UNITS + SUCCESS:
      return {
        ...state,
        setBuildingFloorUnits: { loading: false },
        buildingFloors: addItemIfNotExists(state.buildingFloors, data.item)
      };
    case SET_BUILDING_FLOOR_UNITS + ERROR:
      return { ...state, setBuildingFloorUnits: { loading: false, error } };

    case DELETE_BUILDING_FLOOR + START:
      return { ...state, deleteBuildingFloor: { loading: true } };
    case DELETE_BUILDING_FLOOR + SUCCESS:
      return {
        ...state,
        deleteBuildingFloor: { loading: false },
        buildingFloors: state.buildingFloors.filter((pf) => pf.id !== data.item.id)
      };
    case DELETE_BUILDING_FLOOR + ERROR:
      return { ...state, deleteBuildingFloor: { loading: false, error } };

    case SORT_BUILDING_FLOORS + START:
      return { ...state, sortBuildingFloors: { loading: true } };
    case SORT_BUILDING_FLOORS + SUCCESS:
      return { ...state, sortBuildingFloors: { loading: false }, buildingFloors: data.items };
    case SORT_BUILDING_FLOORS + ERROR:
      return { ...state, sortBuildingFloors: { loading: false, error } };

    case SET_RELOAD_BUILDING_FLOOR_UNITS:
      return { ...state, reloadUnits: action.payload.reload };

    default:
      return { ...state };
  }
}
