import {
  SET_CUSTOMERS_INVITE_METHOD,
  SET_HOMES_VIEW_MODE,
  SET_INVITATION_TYPE,
  SET_INVITED_CUSTOMERS,
  SET_INVITED_HOME,
  SUCCESS
} from '../actions/types';
import { ViewModes } from '../../constants';

const INITIAL_STATE = {
  invitedCustomers: [],
  inviteMethod: null,
  invitedHome: null,
  invitationType: null,
  homesViewMode: ViewModes.LIST,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_INVITED_CUSTOMERS:
      return { ...state, invitedCustomers: action.payload.invitedCustomers };

    case SET_CUSTOMERS_INVITE_METHOD:
      return { ...state, inviteMethod: action.payload.inviteMethod };

    case SET_INVITED_HOME:
      return { ...state, invitedHome: action.payload.invitedHome };

    case SET_INVITATION_TYPE + SUCCESS:
      return { ...state, invitationType: action.payload.invitationType };

    case SET_HOMES_VIEW_MODE:
      return { ...state, homesViewMode: action.payload.homesViewMode };

    default:
      return { ...state };
  }
}
