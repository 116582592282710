import {
  START, SUCCESS, ERROR,
  SAVE_CITY, DELETE_CITY, LIST_CITIES, CLEAN_CITIES, GET_CITY
} from '../actions/types';
import { getError } from '../../utils/requestUtils';

const INITIAL_STATE = {
  cities: [],
  city: null,

  listCities: {},
  getCity: {},
  saveCity: {},
  deleteCity: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case LIST_CITIES + START:
      return { ...state, listCities: { loading: true } };
    case LIST_CITIES + SUCCESS:
      return {
        ...state,
        listCities: { loading: false },
        cities: data.items
      };
    case LIST_CITIES + ERROR:
      return { ...state, listCities: { loading: false, error } };

    case CLEAN_CITIES + SUCCESS:
      return {
        ...state,
        listCities: { loading: false },
        cities: [],
        ...action.payload
      };

    case GET_CITY + START:
      return { ...state, getCity: { loading: true } };
    case GET_CITY + SUCCESS:
      return { ...state, getCity: { loading: false }, city: data.item };
    case GET_CITY + ERROR:
      return { ...state, getCity: { loading: false, error } };

    case SAVE_CITY + START:
      return { ...state, saveCity: { loading: true } };
    case SAVE_CITY + SUCCESS:
      return { ...state, saveCity: { loading: false }, city: data.item };
    case SAVE_CITY + ERROR:
      return { ...state, saveCity: { loading: false, error } };

    case DELETE_CITY + START:
      return { ...state, deleteCity: { loading: true } };
    case DELETE_CITY + SUCCESS:
      return {
        ...state,
        deleteCity: { loading: false },
        cities: state.cities.filter((s) => s.id !== data.item.id)
      };
    case DELETE_CITY + ERROR:
      return { ...state, deleteCity: { loading: false, error } };

    default:
      return { ...state };
  }
}
