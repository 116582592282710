import {
  START, SUCCESS, ERROR,
  SAVE_MODEL_PALETTE, DELETE_MODEL_PALETTE, LIST_MODEL_PALETTES, SET_RELOAD_MODEL_PALETTES
} from '../actions/types';
import { getError } from '../../utils/requestUtils';
import { addItemIfNotExists } from '../../utils/arrayUtils';

const INITIAL_STATE = {
  modelPalettes: [],
  modelPalette: null,
  reload: false,

  listModelPalettes: {},
  saveModelPalette: {},
  deleteModelPalette: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case LIST_MODEL_PALETTES + START:
      return { ...state, listModelPalettes: { loading: true } };
    case LIST_MODEL_PALETTES + SUCCESS:
      return {
        ...state,
        listModelPalettes: { loading: false },
        modelPalettes: data.items
      };
    case LIST_MODEL_PALETTES + ERROR:
      return { ...state, listModelPalettes: { loading: false, error } };

    case SAVE_MODEL_PALETTE + START:
      return { ...state, saveModelPalette: { loading: true } };
    case SAVE_MODEL_PALETTE + SUCCESS:
      return {
        ...state,
        saveModelPalette: { loading: false },
        modelPalette: data.item,
        modelPalettes: addItemIfNotExists(state.modelPalettes, data.item)
      };
    case SAVE_MODEL_PALETTE + ERROR:
      return { ...state, saveModelPalette: { loading: false, error } };

    case DELETE_MODEL_PALETTE + START:
      return { ...state, deleteModelPalette: { loading: true } };
    case DELETE_MODEL_PALETTE + SUCCESS:
      return {
        ...state,
        deleteModelPalette: { loading: false },
        modelPalettes: state.modelPalettes.filter((s) => s.id !== data.item.id)
      };
    case DELETE_MODEL_PALETTE + ERROR:
      return { ...state, deleteModelPalette: { loading: false, error } };

    case SET_RELOAD_MODEL_PALETTES:
      return { ...state, reload: action.payload.reload };

    default:
      return { ...state };
  }
}
