import {
  START,
  SUCCESS,
  ERROR,
  SAVE_TRACKING_PAGE,
  DELETE_TRACKING_PAGE,
  CLEAN_TRACKING_PAGES,
  GET_TRACKING_PAGE,
  LIST_TRACKING_PAGES
} from '../actions/types';
import { getError } from '../../utils/requestUtils';
import { addItemIfNotExists } from '../../utils/arrayUtils';

const INITIAL_STATE = {
  trackingPages: [],
  trackingPage: null,

  listTrackingPages: {},
  getTrackingPage: {},
  saveTrackingPage: {},
  deleteTrackingPage: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case LIST_TRACKING_PAGES + START:
      return { ...state, listTrackingPages: { loading: true } };
    case LIST_TRACKING_PAGES + SUCCESS:
      return {
        ...state,
        listTrackingPages: { loading: false },
        trackingPages: data.items
      };
    case LIST_TRACKING_PAGES + ERROR:
      return { ...state, listTrackingPages: { loading: false, error } };

    case CLEAN_TRACKING_PAGES + SUCCESS:
      return {
        ...state,
        listTrackingPages: { loading: false },
        trackingPages: [],
        ...action.payload
      };

    case GET_TRACKING_PAGE + START:
      return { ...state, getTrackingPage: { loading: true } };
    case GET_TRACKING_PAGE + SUCCESS:
      return { ...state, getTrackingPage: { loading: false }, trackingPage: data.item };
    case GET_TRACKING_PAGE + ERROR:
      return { ...state, getTrackingPage: { loading: false, error } };

    case SAVE_TRACKING_PAGE + START:
      return { ...state, saveTrackingPage: { loading: true } };
    case SAVE_TRACKING_PAGE + SUCCESS:
      return {
        ...state,
        saveTrackingPage: { loading: false },
        trackingPage: data.item,
        trackingPages: addItemIfNotExists(state.trackingPages, data.item)
      };
    case SAVE_TRACKING_PAGE + ERROR:
      return { ...state, saveTrackingPage: { loading: false, error } };

    case DELETE_TRACKING_PAGE + START:
      return { ...state, deleteTrackingPage: { loading: true } };
    case DELETE_TRACKING_PAGE + SUCCESS:
      return {
        ...state,
        deleteTrackingPage: { loading: false },
        trackingPages: state.trackingPages.filter((s) => s.id !== data.item.id)
      };
    case DELETE_TRACKING_PAGE + ERROR:
      return { ...state, deleteTrackingPage: { loading: false, error } };

    default:
      return { ...state };
  }
}
