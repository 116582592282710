export const NavigationMainNames = Object.freeze({
  COMMUNITIES: 'communities',
  MODELS: 'models',
  PRODUCT_TYPES: 'productTypes',
  PACKAGES: 'packages',
  PALETTES: 'palettes',
  PROJECTS: 'projects',
  PROJECT_BUILDINGS: 'projectBuildings',
  BUILDING_MODELS: 'buildingModels',
  QUICK_POSSESSIONS: 'quickPossessions'
});

export const NavigationRelativePaths = Object.freeze({
  [NavigationMainNames.COMMUNITIES]: '/admin/communities/',
  [NavigationMainNames.MODELS]: '/admin/models/',
  [NavigationMainNames.PRODUCT_TYPES]: '/admin/productTypes/',
  [NavigationMainNames.PACKAGES]: '/admin/packages/',
  [NavigationMainNames.PALETTES]: '/admin/palettes/',
  [NavigationMainNames.PROJECT_BUILDINGS]: '/admin/projectBuildings/',
  [NavigationMainNames.PROJECTS]: '/admin/projects/',
  [NavigationMainNames.BUILDING_MODELS]: '/admin/buildingModels/',
  [NavigationMainNames.QUICK_POSSESSIONS]: '/admin/quickPossessions/',
});

export const ReservableShareLinkNames = Object.freeze({
  QUICK_POSSESSION: '/quickPossession/',
  UNIT: '/unit/'
});
