import {
  START,
  SUCCESS,
  ERROR,
  GET_BUILDING_MODELS,
  SAVE_BUILDING_MODEL,
  DELETE_BUILDING_MODEL,
  GET_BUILDING_MODEL,
  SET_BUILDING_MODELS_VIEW_MODE
} from '../actions/types';
import { getError } from '../../utils/requestUtils';
import { ViewModes } from '../../constants';

const INITIAL_STATE = {
  buildingModels: [],
  buildingModel: null,
  viewMode: ViewModes.GRID,

  getBuildingModels: {},
  getBuildingModel: {},
  saveBuildingModel: {},
  deleteBuildingModel: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case GET_BUILDING_MODELS + START:
      return { ...state, getBuildingModels: { loading: true } };
    case GET_BUILDING_MODELS + SUCCESS:
      return { ...state, getBuildingModels: { loading: false }, buildingModels: data.items };
    case GET_BUILDING_MODELS + ERROR:
      return { ...state, getBuildingModels: { loading: false, error } };

    case SAVE_BUILDING_MODEL + START:
      return { ...state, saveBuildingModel: { loading: true } };
    case SAVE_BUILDING_MODEL + SUCCESS:
      return { ...state, saveBuildingModel: { loading: false }, buildingModel: data.item };
    case SAVE_BUILDING_MODEL + ERROR:
      return { ...state, saveBuildingModel: { loading: false, error } };

    case GET_BUILDING_MODEL + START:
      return { ...state, getBuildingModel: { loading: true } };
    case GET_BUILDING_MODEL + SUCCESS:
      return { ...state, getBuildingModel: { loading: false }, buildingModel: data.item };
    case GET_BUILDING_MODEL + ERROR:
      return { ...state, getBuildingModel: { loading: false, error } };

    case DELETE_BUILDING_MODEL + START:
      return { ...state, deleteBuildingModel: { loading: true } };
    case DELETE_BUILDING_MODEL + SUCCESS:
      return {
        ...state,
        deleteBuildingModel: { loading: false },
        buildingModel: data.item,
        buildingModels: state.buildingModels.filter((f) => f.id !== data.item.id)
      };
    case DELETE_BUILDING_MODEL + ERROR:
      return { ...state, deleteBuildingModel: { loading: false, error } };

    case SET_BUILDING_MODELS_VIEW_MODE:
      return { ...state, viewMode: action.payload.viewMode };

    default:
      return { ...state };
  }
}
