import {
  START, SUCCESS, ERROR,
  GET_RESERVE_NOW_WORKFLOW, GET_COMPANY_WORKFLOW, SAVE_COMPANY_WORKFLOW, GET_VERIFIED_WORKFLOW
} from '../actions/types';
import { getError } from '../../utils/requestUtils';

const INITIAL_STATE = {
  reserveNowWorkflow: null,
  verifiedWorkflow: null,
  companyWorkflow: null,

  getReserveNowWorkflow: {},
  getCompanyWorkflow: {},
  saveCompanyWorkflow: {},
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case GET_RESERVE_NOW_WORKFLOW + START:
      return { ...state, getReserveNowWorkflow: { loading: true }, reserveNowWorkflow: null };
    case GET_RESERVE_NOW_WORKFLOW + SUCCESS:
      return { ...state, getReserveNowWorkflow: { loading: false }, reserveNowWorkflow: data.item };
    case GET_RESERVE_NOW_WORKFLOW + ERROR:
      return { ...state, getReserveNowWorkflow: { loading: false, error } };

    case GET_VERIFIED_WORKFLOW + START:
      return { ...state, getVerifiedWorkflow: { loading: true }, verifiedWorkflow: null };
    case GET_VERIFIED_WORKFLOW + SUCCESS:
      return { ...state, getVerifiedWorkflow: { loading: false }, verifiedWorkflow: data.item };
    case GET_VERIFIED_WORKFLOW + ERROR:
      return { ...state, getVerifiedWorkflow: { loading: false, error } };

    case GET_COMPANY_WORKFLOW + START:
      return { ...state, getCompanyWorkflow: { loading: true }, companyWorkflow: null };
    case GET_COMPANY_WORKFLOW + SUCCESS:
      return { ...state, getCompanyWorkflow: { loading: false }, companyWorkflow: data.item };
    case GET_COMPANY_WORKFLOW + ERROR:
      return { ...state, getCompanyWorkflow: { loading: false, error } };

    case SAVE_COMPANY_WORKFLOW + START:
      return { ...state, saveCompanyWorkflow: { loading: true } };
    case SAVE_COMPANY_WORKFLOW + SUCCESS:
      return {
        ...state,
        saveCompanyWorkflow: { loading: false },
        companyWorkflow: data.item,
        ...(data.item.type === state.reserveNowWorkflow?.type
          ? { reserveNowWorkflow: data.item } : {})
      };
    case SAVE_COMPANY_WORKFLOW + ERROR:
      return { ...state, saveCompanyWorkflow: { loading: false, error } };

    default:
      return { ...state };
  }
}
