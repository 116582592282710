import {
  START, ERROR, SUCCESS, GET_ACTIVITIES, CLEAN_ACTIVITIES
} from '../actions/types';
import { getError } from '../../utils/requestUtils';

const INITIAL_STATE = {
  activities: [],
  pagination: null,

  getActivities: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case GET_ACTIVITIES + START:
      return { ...state, getActivities: { loading: true } };
    case GET_ACTIVITIES + SUCCESS:
      return {
        ...state,
        getActivities: { loading: false },
        activities: state.activities.concat(data.result.items),
        pagination: data.result.pagination
      };
    case GET_ACTIVITIES + ERROR:
      return { ...state, getActivities: { loading: false, error } };

    case CLEAN_ACTIVITIES + SUCCESS:
      return {
        ...state, getActivities: { loading: false }, activities: [], pagination: null
      };

    default:
      return { ...state };
  }
}
