import {
  START, SUCCESS, ERROR,
  SAVE_DOCUMENT, DELETE_DOCUMENT, GET_DOCUMENTS, ADD_DOCUMENT_FILE, REMOVE_DOCUMENT_FILE,
  CLEAN_DOCUMENT_FILES
} from '../actions/types';
import { getError } from '../../utils/requestUtils';

const INITIAL_STATE = {
  documentFiles: [],
  documents: [],
  document: null,
  pagination: null,

  getDocuments: {},
  saveDocument: {},
  deleteDocument: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case GET_DOCUMENTS + START:
      return { ...state, getDocuments: { loading: true } };
    case GET_DOCUMENTS + SUCCESS:
      return {
        ...state,
        getDocuments: { loading: false },
        documents: data.result.items,
        pagination: data.result.pagination
      };
    case GET_DOCUMENTS + ERROR:
      return { ...state, getDocuments: { loading: false, error } };

    case SAVE_DOCUMENT + START:
      return { ...state, saveDocument: { loading: true } };
    case SAVE_DOCUMENT + SUCCESS:
      return { ...state, saveDocument: { loading: false }, document: data.item };
    case SAVE_DOCUMENT + ERROR:
      return { ...state, saveDocument: { loading: false, error } };

    case DELETE_DOCUMENT + START:
      return { ...state, deleteDocument: { loading: true } };
    case DELETE_DOCUMENT + SUCCESS:
      return {
        ...state,
        deleteDocument: { loading: false },
        documents: state.documents.filter((s) => s.id !== data.item.id)
      };
    case DELETE_DOCUMENT + ERROR:
      return { ...state, deleteDocument: { loading: false, error } };

    case ADD_DOCUMENT_FILE:
      return { ...state, documentFiles: [...state.documentFiles, action.payload.documentFile] };

    case REMOVE_DOCUMENT_FILE:
      return {
        ...state,
        documentFiles: state.documentFiles.filter((f) => f.id !== action.payload.documentFile.id)
      };

    case CLEAN_DOCUMENT_FILES:
      return { ...state, documentFiles: [] };

    default:
      return { ...state };
  }
}
