import {
  SET_LEVEL_TWO_SUB_MENU,
  SET_SUB_MENU
} from '../actions/types';

const INITIAL_STATE = {
  subMenu: null,
  levelTwoSubMenu: null
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_SUB_MENU:
      return { ...state, subMenu: action.payload.subMenu };
    case SET_LEVEL_TWO_SUB_MENU:
      return { ...state, levelTwoSubMenu: action.payload.levelTwoSubMenu };

    default:
      return { ...state };
  }
}
