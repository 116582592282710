import {
  START,
  SUCCESS,
  ERROR,
  LIST_MORTGAGE_PROVIDERS,
  CLEAN_MORTGAGE_PROVIDERS,
  GET_MORTGAGE_PROVIDER,
  SAVE_MORTGAGE_PROVIDER,
  DELETE_MORTGAGE_PROVIDER,
  SET_RELOAD_MORTGAGE_PROVIDERS
} from '../actions/types';
import { getError } from '../../utils/requestUtils';

const INITIAL_STATE = {
  mortgageProviders: [],
  mortgageProvider: null,
  reloadMortgageProviders: false,

  listMortgageProviders: {},
  getMortgageProvider: {},
  saveMortgageProvider: {},
  deleteMortgageProvider: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case LIST_MORTGAGE_PROVIDERS + START:
      return { ...state, listMortgageProviders: { loading: true } };
    case LIST_MORTGAGE_PROVIDERS + SUCCESS:
      return {
        ...state,
        listMortgageProviders: { loading: false },
        mortgageProviders: data.items
      };
    case LIST_MORTGAGE_PROVIDERS + ERROR:
      return { ...state, listMortgageProviders: { loading: false, error } };

    case CLEAN_MORTGAGE_PROVIDERS + SUCCESS:
      return {
        ...state,
        listMortgageProviders: { loading: false },
        mortgageProviders: [],
        ...action.payload
      };

    case GET_MORTGAGE_PROVIDER + START:
      return { ...state, getMortgageProvider: { loading: true } };
    case GET_MORTGAGE_PROVIDER + SUCCESS:
      return { ...state, getMortgageProvider: { loading: false }, mortgageProvider: data.item };
    case GET_MORTGAGE_PROVIDER + ERROR:
      return { ...state, getMortgageProvider: { loading: false, error } };

    case SAVE_MORTGAGE_PROVIDER + START:
      return { ...state, saveMortgageProvider: { loading: true } };
    case SAVE_MORTGAGE_PROVIDER + SUCCESS:
      return { ...state, saveMortgageProvider: { loading: false }, mortgageProvider: data.item };
    case SAVE_MORTGAGE_PROVIDER + ERROR:
      return { ...state, saveMortgageProvider: { loading: false, error } };

    case DELETE_MORTGAGE_PROVIDER + START:
      return { ...state, deleteMortgageProvider: { loading: true } };
    case DELETE_MORTGAGE_PROVIDER + SUCCESS:
      return {
        ...state,
        deleteMortgageProvider: { loading: false },
        mortgageProviders: state.mortgageProviders.filter((s) => s.id !== data.item.id)
      };
    case DELETE_MORTGAGE_PROVIDER + ERROR:
      return { ...state, deleteMortgageProvider: { loading: false, error } };

    case SET_RELOAD_MORTGAGE_PROVIDERS:
      return { ...state, reloadMortgageProviders: action.payload.reload };

    default:
      return { ...state };
  }
}
