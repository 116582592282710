import { getError } from '../../utils/requestUtils';
import {
  DELETE_BUILDING_MODEL_PACKAGE,
  ERROR,
  LIST_BUILDING_MODEL_PACKAGES,
  RELOAD_BUILDING_MODEL_PACKAGES,
  SAVE_BUILDING_MODEL_PACKAGE,
  START,
  SUCCESS
} from '../actions/types';
import { addItemIfNotExists } from '../../utils/arrayUtils';

const INITIAL_STATE = {
  buildingModelPackages: [],
  buildingModelPackage: undefined,
  reload: false,

  saveBuildingModelPackage: {},
  deleteBuildingModelPackage: {},
  listBuildingModelPackages: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case SAVE_BUILDING_MODEL_PACKAGE + START:
      return { ...state, saveBuildingModelPackage: { loading: true } };
    case SAVE_BUILDING_MODEL_PACKAGE + SUCCESS:
      return {
        ...state,
        buildingModelPackage: data.item,
        saveBuildingModelPackage: { loading: false },
        buildingModelPackages: addItemIfNotExists(state.buildingModelPackages, data.item)
      };
    case SAVE_BUILDING_MODEL_PACKAGE + ERROR:
      return { ...state, saveBuildingModelPackage: { loading: false, error } };

    case LIST_BUILDING_MODEL_PACKAGES + START:
      return { ...state, listBuildingModelPackages: { loading: true } };
    case LIST_BUILDING_MODEL_PACKAGES + SUCCESS:
      return {
        ...state,
        buildingModelPackages: data.items,
        listBuildingModelPackages: { loading: false }
      };
    case LIST_BUILDING_MODEL_PACKAGES + ERROR:
      return { ...state, listBuildingModelPackages: { loading: false, error } };

    case DELETE_BUILDING_MODEL_PACKAGE + START:
      return { ...state, deleteBuildingModelPackage: { loading: true } };
    case DELETE_BUILDING_MODEL_PACKAGE + SUCCESS:
      return {
        ...state,
        buildingModelPackages: state.buildingModelPackages.filter((f) => f.id !== data.item.id),
        deleteBuildingModelPackage: { loading: false }
      };
    case DELETE_BUILDING_MODEL_PACKAGE + ERROR:
      return { ...state, deleteBuildingModelPackage: { loading: false, error } };

    case RELOAD_BUILDING_MODEL_PACKAGES:
      return { ...state, reload: action.payload.reload };

    default:
      return { ...state };
  }
}
