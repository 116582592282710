import {
  START, SUCCESS, ERROR,
  GET_OPTIONS, GET_OPTION, SAVE_OPTION, DELETE_OPTION
} from '../actions/types';
import { getError } from '../../utils/requestUtils';
import { addItemIfNotExists } from '../../utils/arrayUtils';

const INITIAL_STATE = {
  options: [],
  option: null,

  getOptions: {},
  getOption: {},
  saveOption: {},
  deleteOption: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case GET_OPTION + START:
      return { ...state, getOption: { loading: true } };
    case GET_OPTION + SUCCESS:
      return { ...state, getOption: { loading: false }, option: data.item };
    case GET_OPTION + ERROR:
      return { ...state, getOption: { loading: false, error } };

    case GET_OPTIONS + START:
      return { ...state, getOptions: { loading: true } };
    case GET_OPTIONS + SUCCESS:
      return { ...state, getOptions: { loading: false }, options: data.items };
    case GET_OPTIONS + ERROR:
      return { ...state, getOptions: { loading: false, error } };

    case SAVE_OPTION + START:
      return { ...state, saveOption: { loading: true } };
    case SAVE_OPTION + SUCCESS:
      return {
        ...state,
        saveOption: { loading: false },
        option: data.item,
        options: addItemIfNotExists(state.options, data.item)
      };
    case SAVE_OPTION + ERROR:
      return { ...state, saveOption: { loading: false, error } };

    case DELETE_OPTION + START:
      return { ...state, deleteOption: { loading: false } };
    case DELETE_OPTION + SUCCESS:
      return {
        ...state,
        deleteOption: { loading: false },
        options: state.options.filter((o) => o.id !== data.item.id)
      };
    case DELETE_OPTION + ERROR:
      return { ...state, deleteOption: { loading: false, error } };

    default:
      return { ...state };
  }
}
