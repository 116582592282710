import {
  START,
  SUCCESS,
  ERROR,
  SAVE_USER,
  GET_USER,
  GET_USERS,
  CLEAN_USERS,
  DELETE_USER,
  GET_PROFILE,
  SET_RELOAD_USERS
} from '../actions/types';
import { getError } from '../../utils/requestUtils';

const INITIAL_STATE = {
  users: [],
  user: null,
  currentUser: null,
  pagination: null,
  reloadUsers: false,

  getUsers: {},
  getUser: {},
  saveUser: {},
  deleteUser: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case GET_USERS + START:
      return { ...state, getUsers: { loading: true } };
    case GET_USERS + SUCCESS:
      return {
        ...state,
        getUsers: { loading: false },
        users: data.result.items,
        pagination: data.result.pagination
      };
    case GET_USERS + ERROR:
      return { ...state, getUsers: { loading: false, error } };

    case CLEAN_USERS + SUCCESS:
      return {
        ...state, getUsers: { loading: false }, users: [], pagination: null
      };

    case GET_PROFILE + START:
      return { ...state, getProfile: { loading: true } };
    case GET_PROFILE + SUCCESS:
      return { ...state, getProfile: { loading: false }, currentUser: data.profile };
    case GET_PROFILE + ERROR:
      return { ...state, getProfile: { loading: false, error: action.payload } };

    case GET_USER + START:
      return { ...state, getUser: { loading: true } };
    case GET_USER + SUCCESS:
      return { ...state, getUser: { loading: false }, user: data.item };
    case GET_USER + ERROR:
      return { ...state, getUser: { loading: false, error } };

    case SAVE_USER + START:
      return { ...state, saveUser: { loading: true } };
    case SAVE_USER + SUCCESS:
      return { ...state, saveUser: { loading: false }, user: data.item };
    case SAVE_USER + ERROR:
      return { ...state, saveUser: { loading: false, error } };

    case DELETE_USER + START:
      return { ...state, deleteUser: { loading: true } };
    case DELETE_USER + SUCCESS:
      return {
        ...state,
        deleteUser: { loading: false },
        users: state.users.filter((m) => m.id !== data.item.id)
      };
    case DELETE_USER + ERROR:
      return { ...state, deleteUser: { loading: false, error } };

    case SET_RELOAD_USERS:
      return { ...state, reloadUsers: action.payload.reload };

    default:
      return { ...state };
  }
}
