import { getError } from '../../utils/requestUtils';
import {
  DELETE_BUILDING_MODEL_PALETTE,
  ERROR, LIST_BUILDING_MODEL_PALETTES,
  RELOAD_BUILDING_MODEL_PALETTES,
  SAVE_BUILDING_MODEL_PALETTE, START, SUCCESS
} from '../actions/types';
import { addItemIfNotExists } from '../../utils/arrayUtils';

const INITIAL_STATE = {
  buildingModelPalettes: [],
  buildingModelPalette: null,
  reload: false,

  saveBuildingModelPalette: {},
  listBuildingModelPalettes: {},
  deleteBuildingModelPalette: {},
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case SAVE_BUILDING_MODEL_PALETTE + START:
      return { ...state, saveBuildingModelPalette: { loading: true } };
    case SAVE_BUILDING_MODEL_PALETTE + SUCCESS:
      return {
        ...state,
        saveBuildingModelPalette: { loading: false },
        buildingModelPalette: data.item,
        buildingModelPalettes: addItemIfNotExists(state.buildingModelPalettes, data.item)
      };
    case SAVE_BUILDING_MODEL_PALETTE + ERROR:
      return { ...state, saveBuildingModelPalette: { loading: false, error } };

    case DELETE_BUILDING_MODEL_PALETTE + START:
      return { ...state, deleteBuildingModelPalette: { loading: true } };
    case DELETE_BUILDING_MODEL_PALETTE + SUCCESS:
      return {
        ...state,
        deleteBuildingModelPalette: { loading: false },
        buildingModelPalettes: state.buildingModelPalettes.filter((f) => f.id !== data.item.id),
      };
    case DELETE_BUILDING_MODEL_PALETTE + ERROR:
      return { ...state, deleteBuildingModelPalette: { loading: false, error } };

    case LIST_BUILDING_MODEL_PALETTES + START:
      return { ...state, listBuildingModelPalettes: { loading: true } };
    case LIST_BUILDING_MODEL_PALETTES + SUCCESS:
      return {
        ...state,
        buildingModelPalettes: data.items,
        listBuildingModelPalettes: { loading: false }
      };
    case LIST_BUILDING_MODEL_PALETTES + ERROR:
      return { ...state, listBuildingModelPalettes: { loading: false, error } };

    case RELOAD_BUILDING_MODEL_PALETTES:
      return { ...state, reload: action.payload.reload };

    default:
      return { ...state };
  }
}
