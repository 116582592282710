import {
  START, SUCCESS, ERROR, GET_PROJECT, SAVE_PROJECT, CLEAN_PROJECTS,
  DELETE_PROJECT, GET_PROJECTS, SET_PROJECTS_VIEW_MODE
} from '../actions/types';
import { getError } from '../../utils/requestUtils';
import { ViewModes } from '../../constants';

const INITIAL_STATE = {
  projects: [],
  project: null,
  pagination: null,
  viewMode: ViewModes.GRID,

  getProject: {},
  getProjects: {},
  saveProject: {},
  deleteProject: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case GET_PROJECTS + START:
      return { ...state, getProjects: { loading: true } };
    case GET_PROJECTS + SUCCESS:
      return {
        ...state,
        getProjects: { loading: false },
        projects: data.result.items,
        pagination: data.result.pagination
      };
    case GET_PROJECTS + ERROR:
      return { ...state, getProjects: { loading: false, error } };

    case GET_PROJECT + START:
      return { ...state, getProject: { loading: true } };
    case GET_PROJECT + SUCCESS:
      return { ...state, getProject: { loading: false }, project: data.item };
    case GET_PROJECT + ERROR:
      return { ...state, getProject: { loading: false, error } };

    case SAVE_PROJECT + START:
      return { ...state, saveProject: { loading: true } };
    case SAVE_PROJECT + SUCCESS:
      return { ...state, saveProject: { loading: false }, project: data.item };
    case SAVE_PROJECT + ERROR:
      return { ...state, saveProject: { loading: false, error } };

    case CLEAN_PROJECTS + SUCCESS:
      return {
        ...state,
        getProjects: { loading: false },
        projects: [],
        pagination: null,
        ...action.payload
      };

    case DELETE_PROJECT + START:
      return { ...state, deleteProject: { loading: true } };
    case DELETE_PROJECT + SUCCESS:
      return {
        ...state,
        deleteProject: { loading: false },
        project: data.item,
        projects: state.projects.filter((p) => p.id !== data.item.id)
      };
    case DELETE_PROJECT + ERROR:
      return { ...state, deleteProject: { loading: false, error } };

    case SET_PROJECTS_VIEW_MODE:
      return { ...state, viewMode: action.payload.viewMode };

    default:
      return { ...state };
  }
}
