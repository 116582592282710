import { getError } from '../../utils/requestUtils';
import {
  DELETE_TAX_RATE, ERROR, GET_TAX_RATE,
  GET_TAX_RATES, SAVE_TAX_RATE, START, SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
  taxRate: undefined,
  taxRates: [],

  saveTaxRate: {},
  getTaxRate: {},
  getTaxRates: {},
  deleteTaxRate: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case SAVE_TAX_RATE + START:
      return { ...state, saveTaxRate: { loading: true } };
    case SAVE_TAX_RATE + SUCCESS:
      return { ...state, saveTaxRate: { loading: false, taxRate: data.item } };
    case SAVE_TAX_RATE + ERROR:
      return { ...state, saveTaxRate: { loading: false, error } };

    case GET_TAX_RATE + START:
      return { ...state, getTaxRate: { loading: true } };
    case GET_TAX_RATE + SUCCESS:
      return { ...state, getTaxRate: { loading: false }, taxRate: data.item };
    case GET_TAX_RATE + ERROR:
      return { ...state, getTaxRate: { loading: false, error } };

    case GET_TAX_RATES + START:
      return { ...state, getTaxRates: { loading: true } };
    case GET_TAX_RATES + SUCCESS:
      return { ...state, getTaxRates: { loading: false }, taxRates: data.items };
    case GET_TAX_RATES + ERROR:
      return { ...state, getTaxRates: { loading: false, error } };

    case DELETE_TAX_RATE + START:
      return { ...state, deleteTaxRate: { loading: true } };
    case DELETE_TAX_RATE + SUCCESS:
      return {
        ...state,
        deleteTaxRate: { loading: false },
        taxRates: state.taxRates.filter((tr) => tr.id !== data.item.id)
      };
    case DELETE_TAX_RATE + ERROR:
      return { ...state, deleteTaxRate: { loading: false, error } };

    default:
      return { ...state };
  }
}
