import {
  START, SUCCESS, ERROR,
  SAVE_FLOOR_GROUP, DELETE_FLOOR_GROUP, SORT_FLOOR_GROUPS
} from '../actions/types';
import { getError } from '../../utils/requestUtils';

const INITIAL_STATE = {
  floorGroup: null,

  saveFloorGroup: {},
  deleteFloorGroup: {},
  sortFloorGroups: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case SAVE_FLOOR_GROUP + START:
      return { ...state, saveFloorGroup: { loading: true } };
    case SAVE_FLOOR_GROUP + SUCCESS:
      return { ...state, saveFloorGroup: { loading: false }, floorGroup: data.item };
    case SAVE_FLOOR_GROUP + ERROR:
      return { ...state, saveFloorGroup: { loading: false, error } };

    case DELETE_FLOOR_GROUP + START:
      return { ...state, deleteFloorGroup: { loading: true } };
    case DELETE_FLOOR_GROUP + SUCCESS:
      return { ...state, deleteFloorGroup: { loading: false } };
    case DELETE_FLOOR_GROUP + ERROR:
      return { ...state, deleteFloorGroup: { loading: false, error } };

    case SORT_FLOOR_GROUPS + START:
      return { ...state, sortFloorGroups: { loading: true } };
    case SORT_FLOOR_GROUPS + SUCCESS:
      return { ...state, sortFloorGroups: { loading: false } };
    case SORT_FLOOR_GROUPS + ERROR:
      return { ...state, sortFloorGroups: { loading: false, error } };

    default:
      return { ...state };
  }
}
