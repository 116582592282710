import {
  CLEAN_REPORT_ITEMS, SET_REPORT_BUILDING_MODEL, SET_REPORT_COMMUNITY,
  SET_REPORT_MODEL, SET_REPORT_PROJECT, SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
  community: undefined,
  model: undefined,
  buildingModel: undefined,
  project: undefined
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload;

  switch (action.type) {
    case SET_REPORT_COMMUNITY:
      return { ...state, community: data.community };
    case SET_REPORT_MODEL:
      return { ...state, model: data.model };
    case SET_REPORT_PROJECT:
      return { ...state, project: data.project };
    case SET_REPORT_BUILDING_MODEL:
      return { ...state, buildingModel: data.buildingModel };
    case CLEAN_REPORT_ITEMS + SUCCESS:
      return {
        ...state,
        model: undefined,
        community: undefined,
        buildingModel: undefined,
        project: undefined,
        company: undefined
      };
    default:
      return { ...state };
  }
}
