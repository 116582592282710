import {
  START, SUCCESS, ERROR,
  GET_SELLER_SPACES, GET_SELLER_SPACE,
  SAVE_SELLER_SPACE, DELETE_SELLER_SPACE
} from '../actions/types';
import { getError } from '../../utils/requestUtils';
import { addItemIfNotExists } from '../../utils/arrayUtils';

const INITIAL_STATE = {
  sellerSpaces: [],
  sellerSpace: null,

  getSellerSpaces: {},
  getSellerSpace: {},
  saveSellerSpace: {},
  deleteSellerSpace: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action?.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case GET_SELLER_SPACE + START:
      return { ...state, getSellerSpace: { loading: true } };
    case GET_SELLER_SPACE + SUCCESS:
      return {
        ...state,
        getSellerSpace: { loading: false },
        sellerSpace: data.item
      };
    case GET_SELLER_SPACE + ERROR:
      return { ...state, getSellerSpace: { loading: false, error } };

    case GET_SELLER_SPACES + START:
      return { ...state, getSellerSpaces: { loading: true } };
    case GET_SELLER_SPACES + SUCCESS:
      return {
        ...state,
        getSellerSpaces: { loading: false },
        sellerSpaces: data.items
      };
    case GET_SELLER_SPACES + ERROR:
      return { ...state, getSellerSpaces: { loading: false, error } };

    case SAVE_SELLER_SPACE + START:
      return { ...state, saveSellerSpace: { loading: true } };
    case SAVE_SELLER_SPACE + SUCCESS:
      return {
        ...state,
        saveSellerSpace: { loading: false },
        sellerSpaces: addItemIfNotExists(state.sellerSpaces, data.item)
      };
    case SAVE_SELLER_SPACE + ERROR:
      return { ...state, saveSellerSpace: { loading: false, error } };

    case DELETE_SELLER_SPACE + START:
      return { ...state, deleteSellerSpace: { loading: true } };
    case DELETE_SELLER_SPACE + SUCCESS:
      return {
        ...state,
        deleteSellerSpace: { loading: false },
        sellerSpaces: state.sellerSpaces.filter((s) => s.id !== data.item.id)
      };
    case DELETE_SELLER_SPACE + ERROR:
      return { ...state, deleteSellerSpace: { loading: false, error } };

    default:
      return { ...state };
  }
}
