import {
  START, SUCCESS, ERROR,
  GET_OPTION_TYPES, DELETE_OPTION_TYPE, SAVE_OPTION_TYPE, GET_OPTION_TYPE
} from '../actions/types';
import { getError } from '../../utils/requestUtils';
import { addItemIfNotExists } from '../../utils/arrayUtils';

const INITIAL_STATE = {
  optionTypes: [],
  optionType: null,

  getOptionTypes: {},
  getOptionType: {},
  saveOptionType: {},
  deleteOptionType: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case GET_OPTION_TYPE + START:
      return { ...state, getOptionType: { loading: true } };
    case GET_OPTION_TYPE + SUCCESS:
      return { ...state, getOptionType: { loading: false }, optionType: data.item };
    case GET_OPTION_TYPE + ERROR:
      return { ...state, getOptionType: { loading: false, error } };

    case GET_OPTION_TYPES + START:
      return { ...state, getOptionTypes: { loading: false } };
    case GET_OPTION_TYPES + SUCCESS:
      return { ...state, getOptionTypes: { loading: false }, optionTypes: data.items };
    case GET_OPTION_TYPES + ERROR:
      return { ...state, getOptionTypes: { loading: false, error } };

    case SAVE_OPTION_TYPE + START:
      return { ...state, saveOptionType: { loading: true } };
    case SAVE_OPTION_TYPE + SUCCESS:
      return {
        ...state,
        saveOptionType: { loading: false },
        optionType: data.item,
        optionTypes: addItemIfNotExists(state.optionTypes, data.item)
      };
    case SAVE_OPTION_TYPE + ERROR:
      return { ...state, saveOptionType: { loading: false, error } };

    case DELETE_OPTION_TYPE + START:
      return { ...state, deleteOptionType: { loading: true } };
    case DELETE_OPTION_TYPE + SUCCESS:
      return {
        ...state,
        deleteOptionType: { loading: false },
        optionType: data.item,
        optionTypes: state.optionTypes.filter((ot) => ot.id !== data.item.id)
      };
    case DELETE_OPTION_TYPE + ERROR:
      return { ...state, deleteOptionType: { loading: false, error } };

    default:
      return { ...state };
  }
}
