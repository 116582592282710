import {
  SUCCESS,
  SET_IMPORTED_DATA,
  CLEAN_IMPORTED_DATA
} from '../actions/types';

const INITIAL_STATE = {
  importedData: null
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_IMPORTED_DATA:
      return {
        ...state,
        importedData: action.payload.importedData
      };

    case CLEAN_IMPORTED_DATA + SUCCESS:
      return {
        ...state,
        importedData: null,
        ...action.payload
      };

    default:
      return { ...state };
  }
}
