import {
  START,
  SUCCESS,
  ERROR,
  SAVE_COMMUNITY_PRODUCT_TYPE,
  DELETE_COMMUNITY_PRODUCT_TYPE,
  GET_COMMUNITY_PRODUCT_TYPES,
  GET_COMMUNITY_PRODUCT_TYPE,
  SORT_COMMUNITY_PRODUCT_TYPES,
  SET_COMMUNITY_PRODUCT_TYPES
} from '../actions/types';
import { getError } from '../../utils/requestUtils';
import { addItemIfNotExists } from '../../utils/arrayUtils';

const INITIAL_STATE = {
  communityProductTypes: [],
  communityProductType: null,

  getCommunityProductTypes: {},
  getCommunityProductType: {},
  saveCommunityProductType: {},
  deleteCommunityProductType: {},
  sortCommunityProductTypes: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case GET_COMMUNITY_PRODUCT_TYPES + START:
      return { ...state, getCommunityProductTypes: { loading: true } };
    case GET_COMMUNITY_PRODUCT_TYPES + SUCCESS:
      return {
        ...state,
        getCommunityProductTypes: { loading: false },
        communityProductTypes: data.items
      };
    case GET_COMMUNITY_PRODUCT_TYPES + ERROR:
      return { ...state, getCommunityProductTypes: { loading: false, error } };

    case GET_COMMUNITY_PRODUCT_TYPE + START:
      return { ...state, getCommunityProductType: { loading: true } };
    case GET_COMMUNITY_PRODUCT_TYPE + SUCCESS:
      return {
        ...state,
        getCommunityProductType: { loading: false },
        communityProductType: data.item
      };
    case GET_COMMUNITY_PRODUCT_TYPE + ERROR:
      return { ...state, getCommunityProductType: { loading: false, error } };

    case SAVE_COMMUNITY_PRODUCT_TYPE + START:
      return { ...state, saveCommunityProductType: { loading: true } };
    case SAVE_COMMUNITY_PRODUCT_TYPE + SUCCESS:
      return {
        ...state,
        saveCommunityProductType: { loading: false },
        communityProductType: data.item,
        communityProductTypes: addItemIfNotExists(state.communityProductTypes, data.item)
      };
    case SAVE_COMMUNITY_PRODUCT_TYPE + ERROR:
      return { ...state, saveCommunityProductType: { loading: false, error } };

    case DELETE_COMMUNITY_PRODUCT_TYPE + START:
      return { ...state, deleteCommunityProductType: { loading: true } };
    case DELETE_COMMUNITY_PRODUCT_TYPE + SUCCESS:
      return {
        ...state,
        deleteCommunityProductType: { loading: false },
        communityProductTypes: state.communityProductTypes.filter((s) => s.id !== data.item.id)
      };
    case DELETE_COMMUNITY_PRODUCT_TYPE + ERROR:
      return { ...state, deleteCommunityProductType: { loading: false, error } };

    case SET_COMMUNITY_PRODUCT_TYPES:
      return {
        ...state,
        communityProductTypes: action.payload.communityProductTypes
      };

    case SORT_COMMUNITY_PRODUCT_TYPES + START:
      return { ...state, sortCommunityProductTypes: { loading: true } };
    case SORT_COMMUNITY_PRODUCT_TYPES + SUCCESS:
      return {
        ...state,
        sortCommunityProductTypes: { loading: false }
      };
    case SORT_COMMUNITY_PRODUCT_TYPES + ERROR:
      return { ...state, sortCommunityProductTypes: { loading: false, error } };

    default:
      return { ...state };
  }
}
