import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';

import '../locales';
import getRootReducers from './reducers';

const logger = createLogger({
  actionTransformer: (action = {}) => {
    if (action && action.payload) {
      // Delete 'request' because there is a problem with redux-logger
      delete action.payload.request;
    }
    return action;
  },
  predicate: () => process.env.NODE_ENV !== 'production'
});

const middleware = applyMiddleware(promise, thunk, logger);

function configureStore() {
  const store = createStore(getRootReducers(), middleware);

  if (module.hot) {
    module.hot.accept(() => {
      const nextRootReducer = require('./reducers/index').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}

const store = configureStore();
export default store;
