import {
  START, SUCCESS, ERROR,
  SAVE_MODEL_IMAGE, DELETE_MODEL_IMAGE, GET_MODEL_IMAGES,
  SET_MODEL_IMAGES, SORT_MODEL_IMAGES
} from '../actions/types';
import { getError } from '../../utils/requestUtils';

const INITIAL_STATE = {
  modelImages: [],
  modelImage: null,

  getModelImages: {},
  saveModelImage: {},
  deleteModelImage: {},
  sortModelImages: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case GET_MODEL_IMAGES + START:
      return { ...state, getModelImages: { loading: true } };
    case GET_MODEL_IMAGES + SUCCESS:
      return {
        ...state,
        getModelImages: { loading: false },
        modelImages: data.items
      };
    case GET_MODEL_IMAGES + ERROR:
      return { ...state, getModelImages: { loading: false, error } };

    case SAVE_MODEL_IMAGE + START:
      return { ...state, saveModelImage: { loading: true } };
    case SAVE_MODEL_IMAGE + SUCCESS:
      return { ...state, saveModelImage: { loading: false }, modelImage: data.item };
    case SAVE_MODEL_IMAGE + ERROR:
      return { ...state, saveModelImage: { loading: false, error } };

    case SET_MODEL_IMAGES:
      return { ...state, modelImages: action.payload.modelImages };

    case SORT_MODEL_IMAGES + START:
      return { ...state, sortModelImages: { loading: true } };
    case SORT_MODEL_IMAGES + SUCCESS:
      return { ...state, sortModelImages: { loading: false } };
    case SORT_MODEL_IMAGES + ERROR:
      return { ...state, sortModelImages: { loading: false, error } };

    case DELETE_MODEL_IMAGE + START:
      return { ...state, deleteModelImage: { loading: true } };
    case DELETE_MODEL_IMAGE + SUCCESS:
      return {
        ...state,
        deleteModelImage: { loading: false },
        modelImages: state.modelImages.filter((s) => s.id !== data.item.id)
      };
    case DELETE_MODEL_IMAGE + ERROR:
      return { ...state, deleteModelImage: { loading: false, error } };

    default:
      return { ...state };
  }
}
