import {
  START, SUCCESS, ERROR,
  SAVE_TEAM, DELETE_TEAM, LIST_TEAMS, CLEAN_TEAMS, GET_TEAM, SET_RELOAD_TEAMS
} from '../actions/types';
import { getError } from '../../utils/requestUtils';

const INITIAL_STATE = {
  teams: [],
  team: null,
  reloadTeams: false,

  listTeams: {},
  getTeam: {},
  saveTeam: {},
  deleteTeam: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case LIST_TEAMS + START:
      return { ...state, listTeams: { loading: true } };
    case LIST_TEAMS + SUCCESS:
      return {
        ...state,
        listTeams: { loading: false },
        teams: data.items
      };
    case LIST_TEAMS + ERROR:
      return { ...state, listTeams: { loading: false, error } };

    case CLEAN_TEAMS + SUCCESS:
      return {
        ...state,
        listTeams: { loading: false },
        teams: [],
        ...action.payload
      };

    case GET_TEAM + START:
      return { ...state, getTeam: { loading: true } };
    case GET_TEAM + SUCCESS:
      return { ...state, getTeam: { loading: false }, team: data.item };
    case GET_TEAM + ERROR:
      return { ...state, getTeam: { loading: false, error } };

    case SAVE_TEAM + START:
      return { ...state, saveTeam: { loading: true } };
    case SAVE_TEAM + SUCCESS:
      return { ...state, saveTeam: { loading: false }, team: data.item };
    case SAVE_TEAM + ERROR:
      return { ...state, saveTeam: { loading: false, error } };

    case DELETE_TEAM + START:
      return { ...state, deleteTeam: { loading: true } };
    case DELETE_TEAM + SUCCESS:
      return {
        ...state,
        deleteTeam: { loading: false },
        teams: state.teams.filter((s) => s.id !== data.item.id)
      };
    case DELETE_TEAM + ERROR:
      return { ...state, deleteTeam: { loading: false, error } };

    case SET_RELOAD_TEAMS:
      return { ...state, reloadTeams: action.payload.reload };

    default:
      return { ...state };
  }
}
