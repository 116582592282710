import {
  START,
  SUCCESS,
  ERROR,
  LIST_PROPERTY_TAX_RATES,
  GET_PROPERTY_TAX_RATE,
  SAVE_PROPERTY_TAX_RATE,
  DELETE_PROPERTY_TAX_RATE
} from '../actions/types';
import { getError } from '../../utils/requestUtils';

const INITIAL_STATE = {
  propertyTaxRates: [],
  propertyTaxRate: null,

  listPropertyTaxRates: {},
  getPropertyTaxRate: {},
  savePropertyTaxRate: {},
  deletePropertyTaxRate: {},
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case LIST_PROPERTY_TAX_RATES + START:
      return { ...state, listPropertyTaxRates: { loading: true } };
    case LIST_PROPERTY_TAX_RATES + SUCCESS:
      return {
        ...state,
        listPropertyTaxRates: { loading: false },
        propertyTaxRates: data.result.items,
        pagination: data.result.pagination
      };
    case LIST_PROPERTY_TAX_RATES + ERROR:
      return { ...state, listPropertyTaxRates: { loading: false, error } };

    case GET_PROPERTY_TAX_RATE + START:
      return { ...state, getPropertyTaxRate: { loading: true } };
    case GET_PROPERTY_TAX_RATE + SUCCESS:
      return { ...state, getPropertyTaxRate: { loading: false }, propertyTaxRate: data.item };
    case GET_PROPERTY_TAX_RATE + ERROR:
      return { ...state, getPropertyTaxRate: { loading: false, error } };

    case SAVE_PROPERTY_TAX_RATE + START:
      return { ...state, savePropertyTaxRate: { loading: true } };
    case SAVE_PROPERTY_TAX_RATE + SUCCESS:
      return { ...state, savePropertyTaxRate: { loading: false }, propertyTaxRate: data.item };
    case SAVE_PROPERTY_TAX_RATE + ERROR:
      return { ...state, savePropertyTaxRate: { loading: false, error } };

    case DELETE_PROPERTY_TAX_RATE + START:
      return { ...state, deletePropertyTaxRate: { loading: true } };
    case DELETE_PROPERTY_TAX_RATE + SUCCESS:
      return {
        ...state,
        deletePropertyTaxRate: { loading: false },
        propertyTaxRates: state.propertyTaxRates.filter((ptr) => ptr.id !== data.item.id)
      };
    case DELETE_PROPERTY_TAX_RATE + ERROR:
      return { ...state, deletePropertyTaxRate: { loading: false, error } };

    default:
      return { ...state };
  }
}
