export function addOrReplaceChild(array, child) {
  const index = array.findIndex((item) => item.type === child.type);
  if (isAlreadyIncluded(array, child)) return array;

  if (index < 0) return [...array, child];

  return [...array.slice(0, index), child];
}

function isAlreadyIncluded(array, child) {
  return array.some((item) => item.id === child.id);
}
