export function setSortedFloorOptions(floors, floorGroupId, floorOptions) {
  return floors.map((f) => {
    const floorGroups = f.floorGroups.map((fg) => {
      if (fg.id === floorGroupId) return { ...fg, floorOptions };
      return fg;
    });
    return { ...f, floorGroups };
  });
}

export function getFloorOptionIdsByFloorGroupId(floors, floorId) {
  let floorOptionIds = [];
  floors.some((floor) => floor.floorGroups.some((fg) => {
    if (fg.id === floorId) {
      floorOptionIds = fg.floorOptions.map((fo) => fo.id);
      return true;
    }
    return false;
  }));
  return floorOptionIds;
}
