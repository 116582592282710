import {
  START, SUCCESS, ERROR,
  SAVE_SPECIFICATION, DELETE_SPECIFICATION, GET_SPECIFICATIONS
} from '../actions/types';
import { getError } from '../../utils/requestUtils';

const INITIAL_STATE = {
  specifications: [],
  specification: null,

  getSpecifications: {},
  saveSpecification: {},
  deleteSpecification: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case GET_SPECIFICATIONS + START:
      return { ...state, getSpecifications: { loading: true } };
    case GET_SPECIFICATIONS + SUCCESS:
      return {
        ...state,
        getSpecifications: { loading: false },
        specifications: data.items
      };
    case GET_SPECIFICATIONS + ERROR:
      return { ...state, getSpecifications: { loading: false, error } };

    case SAVE_SPECIFICATION + START:
      return { ...state, saveSpecification: { loading: true } };
    case SAVE_SPECIFICATION + SUCCESS:
      return { ...state, saveSpecification: { loading: false }, specification: data.item };
    case SAVE_SPECIFICATION + ERROR:
      return { ...state, saveSpecification: { loading: false, error } };

    case DELETE_SPECIFICATION + START:
      return { ...state, deleteSpecification: { loading: true } };
    case DELETE_SPECIFICATION + SUCCESS:
      return {
        ...state,
        deleteSpecification: { loading: false },
        specifications: state.specifications.filter((s) => s.id !== data.item.id)
      };
    case DELETE_SPECIFICATION + ERROR:
      return { ...state, deleteSpecification: { loading: false, error } };

    default:
      return { ...state };
  }
}
