import {
  START, SUCCESS, ERROR,
  SAVE_PRODUCT_TYPE, DELETE_PRODUCT_TYPE, GET_PRODUCT_TYPES,
  SET_PRODUCT_TYPES, GET_PRODUCT_TYPE, SET_PRODUCT_TYPES_VIEW_MODE
} from '../actions/types';
import { getError } from '../../utils/requestUtils';
import { ViewModes } from '../../constants';

const INITIAL_STATE = {
  productTypes: [],
  productType: null,
  viewMode: ViewModes.GRID,

  getProductTypes: {},
  getProductType: {},
  saveProductType: {},
  deleteProductType: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case GET_PRODUCT_TYPES + START:
      return { ...state, getProductTypes: { loading: true } };
    case GET_PRODUCT_TYPES + SUCCESS:
      return {
        ...state,
        getProductTypes: { loading: false },
        productTypes: data.items
      };
    case GET_PRODUCT_TYPES + ERROR:
      return { ...state, getProductTypes: { loading: false, error } };

    case GET_PRODUCT_TYPE + START:
      return { ...state, getProductType: { loading: true } };
    case GET_PRODUCT_TYPE + SUCCESS:
      return { ...state, getProductType: { loading: false }, productType: data.item };
    case GET_PRODUCT_TYPE + ERROR:
      return { ...state, getProductType: { loading: false, error } };

    case SAVE_PRODUCT_TYPE + START:
      return { ...state, saveProductType: { loading: true } };
    case SAVE_PRODUCT_TYPE + SUCCESS:
      return { ...state, saveProductType: { loading: false }, productType: data.item };
    case SAVE_PRODUCT_TYPE + ERROR:
      return { ...state, saveProductType: { loading: false, error } };

    case DELETE_PRODUCT_TYPE + START:
      return { ...state, deleteProductType: { loading: true } };
    case DELETE_PRODUCT_TYPE + SUCCESS:
      return {
        ...state,
        deleteProductType: { loading: false },
        productTypes: state.productTypes.filter((m) => m.id !== data.item.id)
      };
    case DELETE_PRODUCT_TYPE + ERROR:
      return { ...state, deleteProductType: { loading: false, error } };

    case SET_PRODUCT_TYPES:
      return {
        ...state,
        productTypes: action.payload.productTypes
      };

    case SET_PRODUCT_TYPES_VIEW_MODE:
      return { ...state, viewMode: action.payload.viewMode };

    default:
      return { ...state };
  }
}
