import React from 'react';
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import StoreProvider from './src/store/Provider';
import SocketProvider from './src/providers/Socket';

export const wrapRootElement = ({ element }) => (
  <SocketProvider>
    <StoreProvider element={element} />
  </SocketProvider>
);
