import {
  START, SUCCESS, ERROR,
  SAVE_PALETTE, DELETE_PALETTE, GET_PALETTES, CLEAN_PALETTES, GET_PALETTE
} from '../actions/types';
import { getError } from '../../utils/requestUtils';

const INITIAL_STATE = {
  palettes: [],
  palette: null,

  getPalettes: {},
  getPalette: {},
  savePalette: {},
  deletePalette: {}
};

export default function reducer(state = INITIAL_STATE, action) {
  const data = action.payload?.data;
  const error = getError(action.payload);

  switch (action.type) {
    case GET_PALETTES + START:
      return { ...state, getPalettes: { loading: true } };
    case GET_PALETTES + SUCCESS:
      return {
        ...state,
        getPalettes: { loading: false },
        palettes: data.items
      };
    case GET_PALETTES + ERROR:
      return { ...state, getPalettes: { loading: false, error } };

    case CLEAN_PALETTES + SUCCESS:
      return {
        ...state,
        getPalettes: { loading: false },
        palettes: [],
        ...action.payload
      };

    case GET_PALETTE + START:
      return { ...state, getPalette: { loading: true } };
    case GET_PALETTE + SUCCESS:
      return { ...state, getPalette: { loading: false }, palette: data.item };
    case GET_PALETTE + ERROR:
      return { ...state, getPalette: { loading: false, error } };

    case SAVE_PALETTE + START:
      return { ...state, savePalette: { loading: true } };
    case SAVE_PALETTE + SUCCESS:
      return { ...state, savePalette: { loading: false }, palette: data.item };
    case SAVE_PALETTE + ERROR:
      return { ...state, savePalette: { loading: false, error } };

    case DELETE_PALETTE + START:
      return { ...state, deletePalette: { loading: true } };
    case DELETE_PALETTE + SUCCESS:
      return {
        ...state,
        deletePalette: { loading: false },
        palettes: state.palettes.filter((s) => s.id !== data.item.id)
      };
    case DELETE_PALETTE + ERROR:
      return { ...state, deletePalette: { loading: false, error } };

    default:
      return { ...state };
  }
}
